import {
    Button,
    Dialog, DialogActions, DialogContent,
    DialogTitle,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import React from "react";
import {useState} from "react";
import {Delete, Edit} from "@mui/icons-material";
import CustomCell from "./CustomCell";
import DefaultBox from "../components/UI/DefaultBox";
const InfoTable = (
    {isLoading, fields, data, onDelete, onEdit}
) => {
    const [openConfirm, setOpenConfirm] = useState(false);
    const [selectedId, setSelectedId] = useState(null);

    const handleDeleteClick = (id) => {
        setSelectedId(id);
        setOpenConfirm(true);
    };
    const handleClose = () => {
        setOpenConfirm(false);
        setSelectedId(null);
    };
    return (
        <DefaultBox sx={{px: 4, overflowX: 'auto', width: '90%'}}>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>
                        <Typography variant="subtitle1" fontWeight="bold">Действия</Typography>
                    </TableCell>
                    {(isLoading ? Array.from(new Array(6)): fields).map((item, index) =>
                        <TableCell key={index}>
                            <Typography variant="subtitle1" fontWeight="bold">
                                {isLoading ? "Поле" :item.label}
                            </Typography>
                        </TableCell>
                    )}
                </TableRow>
            </TableHead>
            <TableBody>
                {(isLoading ? Array.from(new Array(15)): data).map((item, index)=> {
                    return (
                        <TableRow key={index}>
                            <TableCell>
                                <IconButton color="primary" onClick={() => onEdit(item)}>
                                    <Edit />
                                </IconButton>
                                <IconButton color="error" onClick={() => handleDeleteClick(item.id)}>
                                    <Delete />
                                </IconButton>
                            </TableCell>
                            {(isLoading ? Array.from(new Array(6)): fields).map((col, index) =>
                                <CustomCell item={item} col={col} key={index} isLoading={isLoading}></CustomCell>
                            )}
                        </TableRow>
                    )
                }
                )}

            </TableBody>
        </Table>
    <Dialog open={openConfirm} onClose={handleClose}>
        <DialogTitle>Подтвердите удаление</DialogTitle>
        <DialogContent>
            <Typography>Вы уверены, что хотите удалить этот элемент?</Typography>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleClose} color="primary">Отмена</Button>
            <Button onClick={() => { onDelete(selectedId); handleClose(); }} color="error">
                Удалить
            </Button>
        </DialogActions>
    </Dialog>
        </DefaultBox>
    )
}

export default InfoTable