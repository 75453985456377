import {Box, Skeleton} from "@mui/material";
import React, {useEffect, useState} from "react";
import Layout from "../components/layout/Layout";
import {useFetching} from "../hooks/useFetching";
import PanelService from "../api/PanelService";
import PanelTable from "../components/PanelTable";
import HeroContent from "../components/layout/HeroContent";
import DefaultBox from "../components/UI/DefaultBox";
import Error from "../components/Error/Error";
import {Helmet} from "react-helmet";

const PanelPage = () => {
    const [panels, setPanels] = useState([]);
    const [fetchPanels, isPanelsLoading, panelsErrors] = useFetching(
        async () => {
            const response = await PanelService.getGroupedPanels();
            setPanels(response);
        }
    )
    useEffect(() => {
        fetchPanels();
    }, [])
    const heroContent =
        <HeroContent
            header={"Прайлист СИП-панелей"}
        />;
    return (
        <Layout heroContent={heroContent}>
            <Helmet><title>Прайс-лист</title></Helmet>

            {panelsErrors && <Error error={panelsErrors} /> }
            {!panelsErrors &&
                <DefaultBox sx={{ mt: 2, overflowX: 'auto', width: {xs: '100%', md: '60%'} }}>
                    {isPanelsLoading ?
                        <Skeleton variant="rectangular" width="100%" sx={{borderRadius: "4px"}} >
                            <div style={{ paddingTop: '100%' }} />
                        </Skeleton>
                        :
                        <PanelTable data={panels} />
                    }
                </DefaultBox>
            }
        </Layout>

    )
}

export default PanelPage;