import {Button} from "@mui/material";
import React from "react";

const CategoryButton = ({title, onClick, isActive}) => {
    return (
        <Button
            variant={isActive ? 'outlined' : 'contained'}
            onClick={onClick}
            sx={(theme)=>({
                textTransform: 'none',
                typography: "h6",
                display: 'block',
                px: 2,
                backgroundColor: theme.palette.primary.second,
                borderColor: theme.palette.primary.borders,
                borderWidth: 1,
            })}
        >
            {title}
        </Button>
    )
}

export default CategoryButton