import FormService from "../../api/FormService";

import {schemaBase} from "../../schemas/schemaBase";
import BaseForm from "./BaseForm";
import DefaultBox from "../UI/DefaultBox";

const SimpleForm = () => {
    const handleSuccess = async (data) => {
        const dataToSend = {
            client_name: data.clientName,
            phone: data.phone,
        };
        await FormService.createSimpleRequest(dataToSend);
    };

    return (
        <DefaultBox>
        <BaseForm
            image={'/images/consult.png'}
            title={'Консультация специалиста'}
            description="Отправьте свои контакты и мы свяжемся с вами!"
            schema={schemaBase}
            onSubmitSuccess={handleSuccess}
            />
        </DefaultBox>
    )
}
export default SimpleForm;