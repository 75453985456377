import {Link} from "react-router-dom";
import {Button} from "@mui/material";

const LinkButton = ({
                        title,
                        link,
                        variant="body2"
}) => {
    return (
        <Button
            component={Link}
            to={link}
            sx={(theme)=>({
                textTransform: 'none',
                typography: variant,
                display: 'block',
                px: 0,
            })}
        >
            {title}
        </Button>

    )
}


export default LinkButton