import React from "react";
import InfoGrid from "../InfoGrid";

const EntityInfo = ({entity, isLoading}) => {
    return (
        <InfoGrid
            data={entity? entity.topParams : []}
            type={"entity"}
            isLoading={isLoading}
            />

    )
}

export default EntityInfo;