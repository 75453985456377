import {useFetching} from "../hooks/useFetching";
import TownhouseService from "../api/TownhouseService";
import React, {useEffect, useState} from "react";
import TownhouseCard from '../components/TownhouseCard';
import TownhouseFilters from "../components/Filters/TownhouseFilters";
import {useSearchParams} from "react-router-dom";
import HeroContent from "../components/layout/HeroContent";
import ListPage from "./ListPage";
import fetchFiltersCallback from "../utils/FetchFiltersCallback";
import {usePagination} from "../hooks/usePagination";

const ListTownhousePage = () => {
    const {page, handlePageChange, setPage} = usePagination()
    const [totalPages, setTotalPages] = useState(5);
    const limit = 9

    const [searchParams, setSearchParams] = useSearchParams();
    const apartmentsParam = searchParams.get('apartments');

    const filters = {
        apartments: apartmentsParam ? Number(apartmentsParam) : null,
    };

    const [townhouses, setTownhouses] = useState([]);
    const [fetchTownhouses, isTownhousesLoading, townhousesErrors] = useFetching(
        fetchFiltersCallback({
            filters,
            page,
            limit,
            service: TownhouseService,
            setEntities: setTownhouses,
            setTotalPages
        })
    )

    useEffect(() => {
        fetchTownhouses();
    }, [page, filters.apartments]);

    const heroContent =
        <HeroContent
            img={true}
            header={"Таунхаусы из СИП-Панелей"}
            description={"Ищете идеальный проект для вашего жилого комплекса? Выберите таунхаусы из СИП-панелей: экоЧистый материал, высокая энергоэффективность и строительство «молниеносное» по Москве и Московской области. Ваш инвестиционный хит сезона!"}
        />;
    return (
        <ListPage
            helmet={"Таунхаусы"}

            heroImage={"/images/townhouses.jpg"}
            heroContent={heroContent}
            FiltersComponent={TownhouseFilters}
            isLoading={isTownhousesLoading}
            items={townhouses}
            errors={townhousesErrors}
            CardComponent={TownhouseCard}
            totalPages={totalPages}
            page={page}
            handlePageChange={handlePageChange}
            searchParams={searchParams}
            setSearchParams={setSearchParams}
            setPage={setPage}
        />

    )
}

export default ListTownhousePage;