import React, { useState } from 'react';
import {
    Typography,
    Grid2
} from '@mui/material';
import {useForm} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { schemaWithArea } from "../../schemas/schemaWithArea";
import SubmitButton from "../UI/Button/SubmitButton";
import InputField from "../UI/Input/InputField";
import DefaultBox from "../UI/DefaultBox";
import ListCheckBox from "../UI/ListCheckBox";
import FormService from "../../api/FormService";
const CalcForm = ({onClose=null}) => {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schemaWithArea),
    });

    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [completion, setCompletion] = useState([]);
    const [finishing, setFinishing] = useState([]);
    const [engineering, setEngineering] = useState([]);
    const [extras, setExtras] = useState([]);
    const completionOptions = ['Домокомплект из СИП-панелей', 'Фундамент', 'Кровля'];
    const finishingOptions = ['Отделка фасада', 'Отделка цоколя', 'Внутренняя отделка'];
    const engineeringOptions = ['Канализация', 'Водоснабжение', 'Вентиляция', 'Освещение'];
    const extrasOptions = ['Входные двери', 'Межкомнатные двери', 'Окна'];
    const handleCheckboxChange = (option, stateArray, setState) => {
        if (stateArray.includes(option)) {
            setState(stateArray.filter((o) => o !== option));
        } else {
            setState([...stateArray, option]);
        }
    };

    const onSubmit = async (data) => {

        const dataToSend = {
            area: data.area,
            completion: completion,
            finishing: finishing,
            engineering: engineering,
            extras: extras,
            client_name: data.clientName,
            phone: data.phone
        };

        try {
            setDisabled(true);
            setSubmitted(false);
            setError(false);
            await FormService.createCalcRequest(dataToSend)
            setSubmitted(true);
            if (onClose) {
                setTimeout(() => { onClose(); }, 1000);
            }

        } catch (error) {
            setError(true);

        }
        setTimeout(() => { setDisabled(false);
            setSubmitted(false);
            setError(false);}, 5000);
    };

    return (
        <DefaultBox >
                <Typography variant="ch3Form" gutterBottom>
                    Расчет стоимости
                </Typography>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <InputField
                        {...register("area")}
                        helperText={errors.area?.message}
                        error={!!errors.area}
                        label={"Площадь (м²)"}
                    />

                    <Grid2 container spacing={2}>
                        <ListCheckBox
                            title={"Комплектация"}
                            list={completion}
                            options={completionOptions}
                            handleChange={(opt) => handleCheckboxChange(opt, completion, setCompletion)}
                            />
                        <ListCheckBox
                            title={"Отделка"}
                            list={finishing}
                            options={finishingOptions}
                            handleChange={(opt) => handleCheckboxChange(opt, finishing, setFinishing)}
                        />
                        <ListCheckBox
                            title={"Инженерные системы"}
                            list={engineering}
                            options={engineeringOptions}
                            handleChange={(opt) => handleCheckboxChange(opt, engineering, setEngineering)}
                        />
                        <ListCheckBox
                            title={"Дополнительно"}
                            list={extras}
                            options={extrasOptions}
                            handleChange={(opt) => handleCheckboxChange(opt, extras, setExtras)}
                        />
                    </Grid2>
                    <Grid2 container spacing={{xs:0, md: 2}}>
                        <Grid2 item size={{xs: 12, sm: 12, md: 6, lg: 6}}>
                            <InputField
                                label={"Ваше имя"}
                                {...register("clientName")}
                                helperText={errors.clientName?.message}
                                error={!!errors.clientName}
                            />
                        </Grid2>
                        <Grid2 item size={{xs: 12, sm: 12, md: 6, lg: 6}}>
                            <InputField
                                {...register("phone")}
                                helperText={errors.phone?.message}
                                error={!!errors.phone}
                                label={"Номер телефона"}
                                placeholder={"+7 000 000 00 00"}
                            />
                        </Grid2>
                    </Grid2>
                    <SubmitButton
                        disabled={disabled}
                        error={error}
                        submitted={submitted}
                    />
                </form>
        </DefaultBox>
    )
}
export default CalcForm