import {Box, Grid2, Skeleton, Typography} from "@mui/material";
import DefaultBox from "./UI/DefaultBox";
import React, {useMemo} from "react";

const InfoGrid = ({
                    data,
                    type,
                    isLoading,
}) => {

    const isCompany = type === "company"
    const borderLogic = useMemo(
        () => (idx, isSmallScreen) => ({
            borderTop: isSmallScreen? idx >= 1 : idx >=2,
            borderLeft: isSmallScreen ? false : (isCompany ? idx === 1 || idx === 3 || idx === 4 : idx === 1 || idx === 3),
        }),
        [isCompany]
    );
    return (
        <DefaultBox sx={{width: "80%", py: 2}}>
            <Grid2 container alignItems="stretch">
                {data.map((param, idx) => {
                    const isSmallScreen = window.innerHeight < 960
                    const {borderTop, borderLeft} = borderLogic(idx, isSmallScreen);
                    const gridSize = isSmallScreen
                        ? { xs: 12, sm: 12 }
                        : param.size || { xs: 12, sm: 12, md: 6, lg: 6 };
                    return (
                        <Grid2 item size={gridSize} key={idx}>
                            <Box sx={{
                                textAlign: "center",
                                my: 2,
                                display: "flex",
                                flexDirection: isCompany && idx === 0 ? "row" : "column",
                                alignItems: "center",
                                justifyContent: "center",
                                height: "100%",
                                borderTop: borderTop ? "1px solid" : "none",
                                borderLeft: borderLeft  ? "1px solid" : "none",
                                borderColor: (theme)=>theme.palette.secondary.borders,


                            }}
                            >

                                <Typography
                                    variant={isCompany ? "h2": "h3"}
                                    component={"div"}
                                >
                                    {!isLoading ? isCompany ? param.label: param.value : <Skeleton width={150}/> }
                                </Typography>
                                <Typography variant="body4">{isCompany ? param.value : param.label}</Typography>


                            </Box>
                        </Grid2>
                    )
                })
                }

            </Grid2>
        </DefaultBox>
    )
}

export default InfoGrid;