import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {Button} from "@mui/material";

const DroppedButton = ({onShow, show, title}) => {
    return (
        <Button
            onClick={onShow}
            sx={{
                alignSelf: 'flex-start',
                display: 'flex',
                justifyContent: 'space-between',
                width: 'fit-content',
                paddingX: 0,
                typography: 'h6'
            }}
        >
            {title}
            {show ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </Button>
    )
}

export default DroppedButton;