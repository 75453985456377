import React from 'react';
import {Typography } from '@mui/material';
import SimpleForm from "../components/Form/SimpleForm";
import CategoryBox from "../components/CategoryBox";
import ImageForm from "../components/Form/ImageForm";
import Layout from "../components/layout/Layout";
import HeroContent from "../components/layout/HeroContent";
import DefaultBox from "../components/UI/DefaultBox";
import {technologyText} from "../consts/texts";
import {formatText} from "../utils/formatText";
import {Helmet} from "react-helmet";

function TechnologyPage() {
    const heroContent =
        <HeroContent
            header={"О технологии"}
        />;
    return (
        <Layout heroContent={heroContent}>
            <Helmet><title>О технологии</title></Helmet>

            <DefaultBox>
                    {technologyText.map((block, idx)=> (
                        <DefaultBox>
                            <Typography variant="ch3" gutterBottom>
                                {block.title}
                            </Typography>
                            <Typography variant="desc" paragraph>
                                {formatText(block.text)}
                            </Typography>
                        </DefaultBox>
                    ))}
                <SimpleForm/>
                <CategoryBox/>
                <ImageForm/>
            </DefaultBox>
        </Layout>

    );
}

export default TechnologyPage;
