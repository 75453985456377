import {Grid2, Typography} from "@mui/material";
import DefaultBox from "./UI/DefaultBox";

const contactInfo = [
    {
        label: "Адрес",
        value: "г. Москва и МО"
    },
    {
        label: "Телефон",
        value: "+7 (966) 171-67-61"
    },
    {
        label: "Режим работы",
        value: "Пн-Вс: 9:00-18:00"
    },
    {
        label: "E-mail",
        value: "housesip@yandex.ru"
    },
]
const ContactBox = () => {
    return (
        <DefaultBox>
            <Typography variant="ch3" gutterBottom>
                Контакты
            </Typography>
            <Grid2 container spacing={2}>
                {contactInfo.map((item, index) => (
                    <Grid2 item size={{xs: 12, sm: 12, md: 3, lg: 3}} key={index}>
                        <DefaultBox sx={{textAlign: 'center'}}>
                            <Typography variant="h6">{item.label}</Typography>
                            <Typography variant="body2">{item.value}</Typography>
                        </DefaultBox>

                    </Grid2>
                ))}
            </Grid2>
        </DefaultBox>
    )
}

export default ContactBox