import {Card, Grid2, Typography} from "@mui/material";
import React from "react";

import ImageCard from "./UI/ImageCard";
import DefaultBox from "./UI/DefaultBox";

const ServiceBox = () => {
    const services = [
        {
            name: "Ландшафтный дизайн",
            image: "/images/service_1.jpg",
        },
        {
            name: "Инженерные системы",
            image: "/images/service_2.jpg",
        },
        {
            name: "Проектирование домов",
            image: "/images/service_3.jpg",
        },
        {
            name: "Фундаментные работы",
            image: "/images/fundament.jpg",
        }
    ]
    return (
        <DefaultBox>
            <Typography variant="ch3" gutterBottom>
                Услуги
            </Typography>
            <Grid2 container spacing={2}>
                {services.map((service, i) => (
                    <Grid2 item size={{xs: 12, sm: 12, md: 6, lg: 6}} key={i}>
                        <Card sx={{ height: '100%' }}>
                            <ImageCard path={service.image} title={service.name}/>
                        </Card>
                    </Grid2>
                ))}

            </Grid2>
        </DefaultBox>
    )
}

export default ServiceBox