import {Box, Grid2, Skeleton, Typography} from "@mui/material";
import React from "react";
import DefaultBox from "../UI/DefaultBox";

const ParamsBox = ({parameters=[], isLoading}) => {
    return (
        <DefaultBox sx={{pt: 0, pb: 2}}>
            <Grid2 container alignItems="stretch">
                {parameters.map((param, idx) => {
                    const isSmallScreen = window.innerHeight < 960
                    const gridSize = isSmallScreen
                        ? { xs: 12, sm: 12 }
                        : param.size || { xs: 12, sm: 12, md: 6, lg: 6 };
                    const borderTop = !isSmallScreen ? idx >=2 : idx >=1
                    const borderLeft = !isSmallScreen ? idx  %2 === 1 : null
                    return (
                        <Grid2 item size={gridSize} key={idx}>
                            <Box sx={{
                                textAlign: "center",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                height: "100%",
                                borderTop: borderTop ? "1px solid" : "none",
                                borderLeft: borderLeft  ? "1px solid" : "none",
                                borderColor: (theme)=>theme.palette.secondary.borders,
                            }}
                            >
                                {isLoading
                                    ?
                                    <Skeleton width="80%" >
                                        <Typography variant="h5">.</Typography>
                                    </Skeleton>
                                    :
                                    <Typography variant="h5">
                                        { param.value}
                                    </Typography>
                                }

                                <Typography variant="body4">{param.label}</Typography>


                            </Box>
                        </Grid2>
                    )
                })
                }
            </Grid2>
        </DefaultBox>
    )
}

export default ParamsBox