import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#1976d2',
            background: "#ffffff",
            footer: "#6489A6",
            second: "#F3FCFF",
            bgForm: "#242730",
            borders: "#302E2F",
            typography:  '#302E2F',
            btns: '#8DB1C0',

        },
        secondary: {
            main: '#dc004e',
            bgInput: "#000000",
            typography: "#FFFFFF"
        },




    },
    typography: {
        allVariants: {
            color: '#302E2F',
        },
        fontFamily: '"Montserrat", sans-serif',
        ch3: {
            fontFamily: '"Montserrat", sans-serif',
            fontSize: "2.4rem",
            fontWeight: 700,
            lineHeight: 1.167,
            letterSpacing: "0em",
            color: '#302E2F',
            display: "block"
        },
        ch3Form: {
            fontFamily: '"Montserrat", sans-serif',
            fontSize: "2.4rem",
            fontWeight: 700,
            lineHeight: 1.167,
            letterSpacing: "0em",
            color: 'white',
            display: "block"
        },
        ch6: {
            fontFamily: '"Montserrat", sans-serif',
            fontSize: "1.25rem",
            fontWeight: 500,
            lineHeight: 1.6,
            letterSpacing: "0.0075em",
            color: "#FFFFFF"
        },
        desc: {
            fontSize: "1.2rem",
            fontWeight: 400,
            lineHeight: 1.5,
            letterSpacing: "0.00938em",
            fontFamily: '"Montserrat", sans-serif',
            color: '#302E2F',
            display: "block"
        },
        title: {
            fontSize: "1.4rem",
            fontWeight: 500,
            lineHeight: 1.5,
            letterSpacing: "0.00938em",
            fontFamily: '"Montserrat", sans-serif',
            color: '#302E2F',
            display: "block"
        },
        info: {
            title: {
                fontSize: "0.5rem",
                lineHeight: 1.5,
                letterSpacing: "0.00938em",
                fontFamily: '"Montserrat", sans-serif',
                color: '#302E2F',
                display: "block"
            },
        },

        h2: { fontFamily: '"Montserrat", sans-serif', fontWeight: 700 },
        h3: { fontFamily: '"Montserrat", sans-serif', fontWeight: 700 },
        h4: { fontFamily: '"Montserrat", sans-serif', fontWeight: 400 },
        h7: { fontFamily: '"Montserrat", sans-serif', fontWeight: 400 },

        body1: { fontFamily: '"Montserrat", sans-serif', fontWeight: 300 },
        body2: { fontFamily: '"Montserrat", sans-serif', fontWeight: 600 },
        body3: { fontFamily: '"Montserrat", sans-serif', fontWeight: 600, color: "#FFFFFF" },

        body4: { fontFamily: '"Montserrat", sans-serif', fontWeight: 500, color: "#7E7E7E" },
        body5: { fontFamily: '"Montserrat", sans-serif', fontWeight: 500, color: "#7E7E7E" },

    },
});

export default theme;