import {Button} from "@mui/material";

const RefButton = ({href, title, variant="body2", sx={}}) => {
    return (
        <Button
            component="a"
            href={href}
            sx={{
                textTransform: 'none',
                display: 'block',
                justifyContent: 'flex-start',
                typography: variant,
                px: 0
            }}
        >
            {title}
        </Button>
    )
}

export default RefButton;