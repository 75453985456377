import {Box, Grid2} from "@mui/material";

const TopContainer = ({children, type}) => {
    const gridStyles = {
        width: '80%',
        margin: '0 auto',
        ...(type ==='nav' && {height: 60}),
        ...(type ==='header' && {py: 2}),

    }
    return (
        <Box sx={(theme)=>({
            width: '100%',
            backgroundColor: type==='nav' ? theme.palette.primary.background : theme.palette.secondary.hero,
            borderBottom: '1px solid',
            borderColor: theme.palette.secondary.borders,
        })}>
            <Grid2
                container
                alignItems="center"
                sx={gridStyles}
            >
                {children}
            </Grid2>
        </Box>
    )
}

export default TopContainer;