import {Button} from "@mui/material";

const ClickButton = ({handleOpen, title, sx }) => {
    const defaultStyles = {
        px: 0,
        textTransform: 'none',
        typography: "h4",
    }

    return (
            <Button
                onClick={()=>handleOpen()}
                sx={{...defaultStyles,
                    ...sx,
                    backgroundColor: (theme) => theme.palette.primary.background}
                }
            >
                {title}
            </Button>
    )
}
export default ClickButton