import React from 'react';
import {Box, Grid2} from '@mui/material';
import DefaultBox from "../UI/DefaultBox";

function HeroBlock({
                       imageUrl,
                       content,
                   }) {
    return (
        <Box
            sx= {(theme)=>({
                width: '100%',
                backgroundImage: imageUrl ? `url(${imageUrl})` : 'none',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundColor: imageUrl ? 'transparent' : theme.palette.primary.second,
                borderBottom: '1px solid',
                borderColor: theme.palette.primary.borders,
                backgroundAttachment: imageUrl ? 'fixed' : 'scroll',
                position: 'relative',
                py: 4,
            })}
        >
            <DefaultBox
                sx={{
                    width: '80%',
                    margin: '0 auto',
                }}
            >
                <Grid2 container sx={{mb: 6, mt: 6}}>
                    <Grid2 item
                           size={{xs: 12,sm:12,  md:12, lg: 6}}
                           sx={{
                               display: "flex",
                               justifyContent: "center",
                           }}
                    >
                        {content}
                    </Grid2>
                    <Grid2 item size={{xs: 0,sm: 0, md:0, lg: 6}} display={"none"}/>
                </Grid2>
            </DefaultBox>
        </Box>
    );
}

export default HeroBlock;
