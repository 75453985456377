import {Grid2, Typography} from "@mui/material";
import HouseCard from "./HouseCard";
import LinkButton from "./UI/Button/LinkButton";
import DefaultBox from "./UI/DefaultBox";
import React from "react";
import Error from "../components/Error/Error"
const ShowerHouses = ({list, isLoading, errors, floors}) => {
    const header = floors === 1 ? "Одноэтажные дома" : "Двухэтажные дома"
    return (
        <DefaultBox>
            <Typography variant="h4" gutterBottom>
                {header}
            </Typography>
            {errors && <Error error={errors} />}
            {!errors &&
                <Grid2 container spacing={2} alignItems="stretch">
                    {(isLoading ? Array.from(new Array(6)) : list).map((item, index) => (
                        <Grid2 item size={{xs: 12, sm: 12, md: 4, lg: 4}} sx={{display: "flex"}}  key={index}>
                            <HouseCard item={item}/>
                        </Grid2>
                    ))}
                </Grid2>
            }

            <DefaultBox sx={{ textAlign: 'center', mt: 2 }}>
                <LinkButton title={"Все проекты"} link={`/houses?floors=${floors}`}/>
            </DefaultBox>

        </DefaultBox>
    )
}


export default ShowerHouses;