import DefaultBox from "../../../components/UI/DefaultBox";
import {useFetching} from "../../../hooks/useFetching";
import React, {useEffect, useState} from "react";
import CustomPagination from "../../../components/UI/CustomPagination";
import {IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography} from "@mui/material";
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import RequestService from "../../../api/RequestService";
import CustomCell from "../../CustomCell";
import {usePagination} from "../../../hooks/usePagination";
import Error from "../../../components/Error/Error"

const BaseRequestAdmin = ({fields, request_type, header="Заявки"}) => {
    const {page, handlePageChange} = usePagination()
    const [totalPages, setTotalPages] = useState(1);
    const limit = 15

    const token = localStorage.getItem("token");

    const defaultFields = [
        { name: 'id', label: 'id', type: 'text', required: true },
        { name: 'client_name', label: 'Имя клиента', type: 'text', required: true },
        { name: 'phone', label: 'Телефон', type: 'text', required: true },

    ]
    const categoryFields = [...defaultFields, ...fields]

    const [entities, setEntities] = useState([]);
    const [fetchEntities, isEntitiesLoading, entitiesErrors] = useFetching(
        async () => {
            const query = {}
            query.request_type = request_type;
            query.page = page
            query.limit = limit
            const response = await RequestService.getAll(query, token);
            setEntities(response.data);
            const totalItems = response.totalItems
            setTotalPages(Math.ceil(totalItems / limit));
        }
    )
    const [fetchChecked, isCheckedLoading, checkedErrors] = useFetching(
        async (item) => {
            const query = {checked: !item.checked}
            return await RequestService.update(item.id, query, token);
        }
    )
    const toggleChecked = async (item) => {
        const updated = await fetchChecked(item)
        if (checkedErrors) {
            return
        }
        setEntities(prev =>
            prev.map((x) => x.id === item.id ? updated : x)
        );
    };
    useEffect(() => {
        fetchEntities();
    }, [page]);
    return (
        <DefaultBox>
            <DefaultBox sx={{px: 4, mt: 5}}>
                <Typography variant="h5">{header}</Typography>
            </DefaultBox>
            <DefaultBox sx={{px: 4, width: '90%'}}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <Typography variant="subtitle1" fontWeight="bold">Статус</Typography>
                            </TableCell>
                            {categoryFields.map((category, index) => (
                                <TableCell key={index}>
                                    <Typography variant="subtitle1" fontWeight="bold">
                                        {category.label}
                                    </Typography>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {entitiesErrors && <Error>{entitiesErrors}</Error>}
                        {isEntitiesLoading ? (
                            <TableRow>
                                <TableCell>
                                    <Typography>Статус</Typography>
                                </TableCell>
                                {categoryFields.map((_, index)=>
                                    <TableCell key={index}>
                                        <Typography>Значение</Typography>
                                    </TableCell>
                                )}

                            </TableRow>
                        ) : (
                            entities.map((item, index)=> (
                                    <TableRow key={index}>
                                        <TableCell>
                                                <IconButton
                                                    color="primary"
                                                    onClick={() => toggleChecked(item)}>
                                                    {item.checked &&
                                                        <DoneIcon />
                                                    }
                                                    {!item.checked &&
                                                        <CloseIcon />
                                                    }
                                                </IconButton>
                                        </TableCell>
                                        {categoryFields.map((col, index) =>
                                            <CustomCell item={item} col={col} isLoading={isEntitiesLoading} key={index} />
                                        )}
                                    </TableRow>
                            ))
                            )}
                    </TableBody>
                </Table>
            </DefaultBox>
            <DefaultBox>
                <CustomPagination page={page} totalPages={totalPages} onChange={handlePageChange} />
            </DefaultBox>
        </DefaultBox>
    )
}

export default BaseRequestAdmin