import React from 'react';
import TownhouseService from '../api/TownhouseService';
import BaseDetail from '../components/BaseDetail';

function mapTownhouseData(raw) {

    return {
        id: raw.id,
        title: raw.title,
        price: raw.price,
        description: raw.description || '',
        main_image: raw.main_image,
        other_images: raw.other_images || [],
        apartments: raw.apartments || 0,
        topParams: [
            { label: 'Общая площадь', value: `${raw.total_area} м²` },
            { label: 'Плащадь квартиры', value: `${raw.apartment_area} м²` },
            { label: 'Кол-во квартир', value: raw.apartments },
            { label: 'Кол-во спален', value: raw.count_rooms },
        ],
    };
}

function TownhousePage() {
    return (
        <BaseDetail
            service={TownhouseService}
            mapData={mapTownhouseData}
        />
    );
}

export default TownhousePage;
