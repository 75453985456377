import React, { useState } from 'react';
import { Box, TextField, Button, Typography, Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {useFetching} from "../hooks/useFetching";
import LoginService from "../api/LoginService";
import {Helmet} from "react-helmet";

function LoginPage() {
    const navigate = useNavigate();

    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const [fetchLogin, isLoginLoading, loginErrors] = useFetching(
        async () => {
            const response = await LoginService.login({login, password});
            const {access_token} = response.data
            localStorage.setItem('token', access_token);
            navigate('/admin/');
        }
    )

    const handleSubmit = async (e) => {
        e.preventDefault();
        fetchLogin()
    };

    return (
        <Box
            sx={{
                minHeight: '100vh',
                bgcolor: '#f3f3f3',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Helmet><title>Логин</title></Helmet>

            <Paper sx={{ p: 3, width: 300 }}>
                <Typography variant="h6" textAlign="center" mb={2}>
                    Вход
                </Typography>
                <form onSubmit={handleSubmit}>
                    <TextField
                        label="Логин"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={login}
                        onChange={(e) => setLogin(e.target.value)}
                    />
                    <TextField
                        label="Пароль"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    {loginErrors && (
                        <Typography color="error" variant="body2" mt={1}>
                            {loginErrors}
                        </Typography>
                    )}
                    <Button
                        type="submit"
                        variant="contained"
                        disabled={isLoginLoading}
                        fullWidth
                        sx={{ mt: 2 }}>
                        {isLoginLoading ? "Загрузка..." : "Войти"}
                    </Button>
                </form>
            </Paper>
        </Box>
    );
}

export default LoginPage;
