import {Grid2, Typography} from "@mui/material";
import DefaultBox from "../UI/DefaultBox";
import React, {useState} from "react";
import {FormProvider, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import InputField from "../UI/Input/InputField";
import SubmitButton from "../UI/Button/SubmitButton";

const BaseForm = ({
                        image,
                        title,
                        description,
                        schema,
                        onSubmitSuccess,
                        onSubmitError,
                        children

}) => {
    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState(false);
    const [disabled, setDisabled] = useState(false);

    const methods = useForm({
        resolver: schema ? yupResolver(schema) : undefined,
    });
    const { handleSubmit, formState: { errors }, register, reset } = methods;
    const onSubmit = async (data) => {
        try {
            setDisabled(true);
            setSubmitted(false);
            setError(false);
            await onSubmitSuccess(data);
            setSubmitted(true);
            reset()
        } catch (err) {
            setError(true);
            if (onSubmitError) {
                onSubmitError(err);
            }
        }
        setTimeout(() => {
            setDisabled(false);
            setSubmitted(false);
            setError(false);
        }, 5000);
    }
    return (
        <DefaultBox sx={{py: 0}}>
            <Grid2 container alignItems="stretch">
                <Grid2 item
                       size={{xs: 12, sm: 12, md:3, lg: 3}}
                       sx={{
                           display: "flex",
                           alignItems: "stretch"
                       }}
                >
                    <DefaultBox
                        sx={{
                            py: 0,
                            position: 'relative',
                            backgroundColor: (theme) => theme.palette.primary.second,
                            height: '100%',
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            overflow: "hidden",
                        }}
                    >
                        <img
                            src={image}
                            alt={"left_side"}
                            style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                            }}
                        />
                    </DefaultBox>
                </Grid2>
                <Grid2 item size={{xs: 12, sm: 12, md: 9, lg: 9}} sx={{ display: "flex", flexDirection: "column" }}>
                    <DefaultBox  sx={{
                        backgroundColor: (theme) => theme.palette.primary.bgForm,
                        flex: 1,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        px: 2,
                        boxSizing: "border-box"
                    }}>
                        <FormProvider {...methods}>
                        <form onSubmit={handleSubmit(onSubmit)}>

                            <DefaultBox sx={{py: 0}}>
                                <Grid2 container
                                       spacing={{xs:0, md: 2}}
                                       alignItems="stretch"
                                >

                                    <Grid2 item size={{xs: 12, sm: 12, md: 12, lg: 12}}>
                                        <Typography variant="ch3Form" gutterBottom>{title}</Typography>
                                        <Typography variant="ch6" gutterBottom>{description}</Typography>
                                        {children}
                                    </Grid2>

                                    <Grid2 item size={{xs: 12, sm: 12, md: 4, lg: 4}}>
                                        <InputField
                                            label="Номер телефона"
                                            {...register("phone")}
                                            errors={!!errors.phone}
                                            helperText={errors.phone?.message}
                                            placeholder={"+7 900 000 00 00"}
                                        />
                                    </Grid2>
                                    <Grid2 item size={{xs: 12, sm: 12, md: 4, lg: 4}}>
                                        <InputField
                                            label="Ваше имя"
                                            {...register("clientName")}
                                            errors={!!errors.clientName}
                                            helperText={errors.clientName?.message}
                                        />
                                    </Grid2>
                                    <Grid2 item size={{xs: 12, sm: 12, md: 4, lg: 4}}>
                                        <SubmitButton
                                            disabled={disabled}
                                            error={error}
                                            sx={{
                                                width: "100%",
                                                minHeight: "56px",
                                            }}
                                            submitted={submitted}
                                        />
                                    </Grid2>

                                </Grid2>
                                <DefaultBox sx={{py: 0, mt: 1}}>
                                    <Typography variant="body4" gutterBottom>Я соглашаюсь на обработку моих персональных данных в соответствии с требованиями Федерального закона РФ от 27 июля 2006 года № 152-ФЗ «О персональных данных»</Typography>
                                </DefaultBox>

                            </DefaultBox>
                        </form>
                        </FormProvider>
                    </DefaultBox>

                </Grid2>

            </Grid2>

        </DefaultBox>
    )
}
export default BaseForm