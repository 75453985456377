import BaseRequestAdmin from "./BaseRequestAdmin";

const ImageReqPage = () => {
    const fields = [{ name: 'image_path', label: 'Изображение', type: 'file', required: true }]
    return (
        <BaseRequestAdmin
            fields={fields}
            request_type={'IMAGE_FORM'}
            header={"Проекты"}

        />
    )
}
export default ImageReqPage;