import {Box, CardMedia, Skeleton, Typography} from "@mui/material";
import React from "react";
import LabelBox from "./LabelBox";

const ImageCard = ({path, title, isLoading}) => {
    if (isLoading) {
        return (
            <Box sx={{position: 'relative'}}>
                <Skeleton sx={{height: 300}} animation="wave" variant="rectangular"/>
            </Box>
        )
    }
    return (
        <Box sx={{position: 'relative'}}>
                    <CardMedia
                        component="img"
                        sx={{
                            width: '100%',
                            height: 300,
                            objectFit: 'cover',
                        }}
                        image={path}
                        alt={title}
                    />
                    <LabelBox>
                        <Typography variant="title" sx={{fontWeight: 'bold'}}>
                            {title}
                        </Typography>
                    </LabelBox>
        </Box>
    )
}

export default ImageCard;