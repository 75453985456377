import {Checkbox, FormControlLabel, Grid2, Typography} from "@mui/material";
import React from "react";

const ListCheckBox = ({options, list, handleChange, title}) => {
    return (
        <Grid2 item size={{xs: 12, md: 6}} >
            <Typography variant="ch6" sx={{ mb: 1 }}>
                {title}:
            </Typography>

            <Grid2 container spacing={1}>
                {options.map((opt) => (
                    <Grid2 item size={{xs: 12}} key={opt}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={list.includes(opt)}
                                    onChange={()=>handleChange(opt)}
                                    sx={{
                                        color: "gray",
                                        "&.Mui-checked": {
                                            color: "white",
                                        },
                                        "&.Mui-checked:hover": {
                                            backgroundColor: "inherit",
                                        },

                                        "&:hover": {
                                            backgroundColor: "rgba(255, 255, 255, 0.1)",
                                        },
                                    }}
                                />
                            }
                            label={<Typography variant="body3">
                                {opt}
                            </Typography>}
                        />
                    </Grid2>
                ))}
            </Grid2>
        </Grid2>
    )
}

export default ListCheckBox;