import api from "./api";

class ReviewsService {
    static async getAll(query={}) {
        const response = await api.get('api/reviews');
        return response.data;
    }
    static async create(formData, token){
        const response = await api.post(
            `/api/reviews/`,
            formData,
            {
                headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${token}` },
            });
        return response.data
    }
    static async deleteById(id, token) {
        const response = await api.delete(`/api/reviews/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });
        return response.data;
    }
    static async update(id, formData, token){
        const response = await api.patch(
            `/api/reviews/${id}`,
            formData,
            {
                headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${token}` },
            });
        return response.data
    }
}

export default ReviewsService;