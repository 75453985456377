import {Pagination} from "@mui/material";
import React from "react";

const CustomPagination = ({
                              totalPages,
                              page,
                              handleChange
}) => {

    return (
        <Pagination
            count={totalPages}
            page={page}
            onChange={handleChange}
            sx={(theme)=>({
                "& .MuiPagination-ul": {
                    justifyContent: "center",
                },
                "& .MuiPaginationItem-root": {
                    borderRadius: "8px",
                    border: "1px solid",
                    borderColor: theme.palette.primary.btns,
                },
                "& .Mui-selected": {
                    backgroundColor: theme.palette.primary.btns,
                    color: "white",
                    border: "1px solid",
                    borderColor: theme.palette.primary.btns,
                    borderRadius: "8px",
                },
                "& .MuiPaginationItem-ellipsis": {
                    border: "none",
                },
            })}
        />
    )
}

export default CustomPagination