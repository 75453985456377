import { useState } from 'react';
import {
    IconButton
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CalcForm from "../Form/CalcForm";
import ModalForm from "../UI/ModalForm";
import ClickButton from "../UI/Button/ClickButton";
import LinkButton from "../UI/Button/LinkButton";
import MenuButton from "../UI/Button/MenuButton";
import DroppedMenu from "../UI/DroppedMenu";
import GridItemNavBar from "../UI/GridItemNavBar";
import TopContainer from "../UI/TopContainer";
import {housesLinks, companyLinks} from "../../consts/links";
import MobileMenu from "../ToggleMenu/MobileMenu";

function NavBar() {
    const [anchorHouses, setAnchorHouses] = useState(null);
    const handleOpenHouses = (event) => setAnchorHouses(event.currentTarget);
    const handleCloseHouses = () => setAnchorHouses(null);
    const [anchorCompany, setAnchorCompany] = useState(null);
    const handleOpenCompany = (event) => setAnchorCompany(event.currentTarget);
    const handleCloseCompany = () => setAnchorCompany(null);

    const [openCalc, setOpenCalc] = useState(false);
    const handleOpenCalc = () => setOpenCalc(true);
    const handleCloseCalc = () => setOpenCalc(false);

    const [open, setOpen] = useState(false);
    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
    };
    return (
            <TopContainer type={"nav"}>
                <GridItemNavBar size={{ xs: 2, md: 0 }} display={{ xs: 'flex', md: 'none' }}>
                    <IconButton onClick={toggleDrawer(true)}>
                        <MenuIcon />
                    </IconButton>
                    <MobileMenu openMenu={open} onClick={(value) => toggleDrawer(value)}></MobileMenu>
                </GridItemNavBar>
                <GridItemNavBar size={{ xs: 10, md: 4 }} display={{xs: 'flex', md: 'flex'}} align={{md:"left", xs: "center"}}>
                    <ClickButton handleOpen={handleOpenCalc} title={"Калькулятор"}/>
                    <ModalForm open={openCalc} onClose={handleCloseCalc}>
                        <CalcForm onClose={handleCloseCalc}/>
                    </ModalForm>
                </GridItemNavBar>



                <GridItemNavBar>
                    <MenuButton
                        onClick={handleOpenHouses}
                        title={"Дома"}
                    />
                    <DroppedMenu
                        anchorEl={anchorHouses}
                        handleClose={handleCloseHouses}
                        links={housesLinks}
                    />
                </GridItemNavBar>

                <GridItemNavBar>
                    <LinkButton title={"Таунхаусы"} link={"/townhouses"}/>
                </GridItemNavBar>
                <GridItemNavBar>
                    <LinkButton title={"О технологии"} link={"/technology"}/>
                </GridItemNavBar>

                <GridItemNavBar>
                    <MenuButton
                        onClick={handleOpenCompany}
                        title={"О нас"}
                    />
                    <DroppedMenu
                        anchorEl={anchorCompany}
                        handleClose={handleCloseCompany}
                        links={companyLinks}
                    />
                </GridItemNavBar>
            </TopContainer>

    );
}

export default NavBar;
