import React from 'react';
import {Typography} from '@mui/material';
import BaseFilter from "./BaseFilter";
import DefaultBox from "../UI/DefaultBox";
import CategoryButton from "../UI/Button/CategoryButton";
const ranges = [
    "до 50 м2",
    "50-100 м2",
    "100-150 м2",
    "150-200 м2",
    "более 200 м2",
];
function HouseFilters({ searchParams, setSearchParams, setPage }) {
    const floors = searchParams.get('floors');
    const garage = searchParams.get('garage');
    const terrace = searchParams.get('terrace');
    const areaCode = searchParams.get('areaCode');

    const floorsNum = floors ? Number(floors) : null;
    const garageBool = (garage === 'true');
    const terraceBool = (terrace === 'true');
    const areaNum = areaCode ? Number(areaCode) : null;

    const updateParams = (obj) => {
        const paramsObj = {};
        if (obj.floors) paramsObj.floors = obj.floors;
        if (obj.garage) paramsObj.garage = 'true';
        if (obj.terrace) paramsObj.terrace = 'true';
        if (obj.areaCode) paramsObj.areaCode = obj.areaCode;

        setSearchParams(paramsObj);
    }
    const handleFilterFloors = (value) => {
        const newFloors = (floorsNum === value) ? null : value;
        updateParams({ floors: newFloors, garage: garageBool, terrace: terraceBool, areaCode: areaNum });
        setPage(1);
    };

    const handleFilterGarage = () => {
        const newGarage = !garageBool;
        updateParams({ floors: floorsNum, garage: newGarage, terrace: terraceBool, areaCode: areaNum });
        setPage(1);
    };

    const handleFilterTerrace = () => {
        const newTerrace = !terraceBool;
        updateParams({ floors: floorsNum, garage: garageBool, terrace: newTerrace, areaCode: areaNum });
        setPage(1);
    };

    const handleAreaFilter = (code) => {
        const newArea = (areaNum === code) ? null : code;
        updateParams({ floors: floorsNum, garage: garageBool, terrace: terraceBool, areaCode: newArea });
        setPage(1);
    };
    const isActive = (code) => {
        return areaNum === code;
    };
    return (
        <DefaultBox>
            <DefaultBox sx={{
                display: "flex",
                alignItems: { xs: "flex-start", md: "center" },
                gap: 2,
                py: 0,
                flexDirection: { xs: "column", sm: "column", md: "row" }
            }}>

                <Typography variant="h4" sx={{ flexShrink: 0 }}>Типы домов:</Typography>
                    <DefaultBox sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: 1,
                        py: 0,
                        flexDirection: { xs: "column", sm: "column", md: "row" }
                    }}>
                        <CategoryButton
                            isActive={floorsNum === 1}
                            onClick={() => handleFilterFloors(1)}
                            title={"Одноэтажные"}
                            />
                        <CategoryButton
                            isActive={floorsNum === 2}
                            onClick={() => handleFilterFloors(2)}
                            title={"Двухэтажные"}
                        />
                        <CategoryButton
                            isActive={garageBool}
                            onClick={handleFilterGarage}
                            title={"С гаражом"}
                        />
                        <CategoryButton
                            isActive={terraceBool}
                            onClick={handleFilterTerrace}
                            title={"С террасой"}
                        />
                </DefaultBox>
            </DefaultBox>
            <DefaultBox sx={{
                display: "flex",
                alignItems: { xs: "flex-start", md: "center" },
                gap: 2,
                py: 0,
                flexDirection: { xs: "column", sm: "column", md: "row" }
            }}>
                <Typography variant="h4" sx={{ flexShrink: 0 }}>Площадь:</Typography>
                <BaseFilter
                    handleFilter={handleAreaFilter}
                    func={(num)=> num + 1}
                    list={ranges}
                    isActive={isActive}
                />
            </DefaultBox>
        </DefaultBox>
    );
}

export default HouseFilters;
