import LinkButton from "./Button/LinkButton";
import React from "react";

const LinksList = ({list}) => {
    return (
            <>
            {list.map(( item, index ) => (
                <LinkButton link={item.link} title={item.title} key={index} variant={"body3"}/>
            ))}
            </>
    )
}

export default LinksList