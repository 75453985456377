import {
    AppBar,
    Box,
    Button,
    Collapse,
    Drawer,
    List, ListItem,
    ListItemButton,
    ListItemText,
    Toolbar,
    Typography
} from "@mui/material";
import {Outlet, useNavigate} from "react-router-dom";

import { Link } from "react-router-dom";
import React, {useState} from "react";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import {Helmet} from "react-helmet";
const entities = [
    {
        title: 'Дома',
        path: '/admin/houses',
    },
    {
        title: 'Таунхаусы',
        path: '/admin/townhouses',
    },
    {
        title: 'Отзывы',
        path: '/admin/reviews',
    },
    {
        title: 'Портфолио',
        path: '/admin/portfolio',
    },
    {
        title: 'Панели',
        path: '/admin/panels',
    },
]
const AdminLayout = () => {
    const [requestsOpen, setRequestsOpen] = useState(false);
    const navigate = useNavigate();

    const handleLogout = () => {
        localStorage.removeItem('token');
        navigate('/admin/login');
    };

    return (
        <Box sx={{ display: 'flex' }}>
            <Helmet><title>Админ-панель</title></Helmet>
            <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography variant="h6" noWrap component="div">
                        Панель администратора
                    </Typography>
                    <Button color="inherit" onClick={handleLogout}>
                        Выйти
                    </Button>
                </Toolbar>
            </AppBar>

            <Drawer
                variant="permanent"
                sx={{
                    width: 240,
                    [`& .MuiDrawer-paper`]: { width: 240, boxSizing: 'border-box' },
                }}
            >
                <Toolbar />
                <List>
                    {entities.map((item) => (
                        <ListItemButton component={Link} to={item.path} key={item.id}>
                            <ListItemText primary={item.title} />
                        </ListItemButton>
                    ))}
                    <ListItem disablePadding>
                        <ListItemButton onClick={() => setRequestsOpen(!requestsOpen)}>
                            <ListItemText primary="Заявки" />
                            {requestsOpen ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                    </ListItem>
                    <Collapse in={requestsOpen} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItemButton component={Link} to="/admin/image-req">
                                <ListItemText primary="Проекты" />
                            </ListItemButton>
                            <ListItemButton component={Link} to="/admin/simple-req" >
                                <ListItemText primary="Консультация" />
                            </ListItemButton>
                            <ListItemButton component={Link} to="/admin/house-req">
                                <ListItemText primary="По домам" />
                            </ListItemButton>
                            <ListItemButton component={Link} to="/admin/calc-req">
                                <ListItemText primary="На расчет" />
                            </ListItemButton>
                        </List>
                    </Collapse>
                </List>
            </Drawer>

                <Outlet/>
        </Box>
    )
}
export default AdminLayout;