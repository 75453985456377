import {Card, CardContent, Grid2, Rating, Skeleton, Typography} from "@mui/material";
import api from "../../api/api";
import React from "react";
import {formatDate} from "../../utils/FormatDate";

const ReviewCard = ({review}) => {
    const isLoading = !review;
    const createdAt = review ? review.created_at : null
    const rating = review ? review.rating : null
    const image = review ? review.image : null
    const intro = review ? review.intro : null
    const mainText = review ? review.main_text : null;
    const clientName = review ? review.client_name : null;
    return (
        <Card
            sx={{
                mb: 2,
                border: '1px solid #ccc',
            }}
        >
            <CardContent>
                <Grid2 container spacing={2}>
                    <Grid2 item size={{xs: 12, sm: 12, md: 4, lg: 4}}>
                        {isLoading ? (
                                <Skeleton width="100%">
                                    <Typography variant="ch3">.
                                    </Typography>
                                </Skeleton>
                            ) :
                            <Typography variant="ch3">{clientName}</Typography>
                        }
                        {isLoading ?
                            <Skeleton width="100%">
                                <Typography variant="desc" sx={{ mb: 1 }}>.
                                </Typography>
                            </Skeleton> :
                            <Typography variant="desc" sx={{ mb: 1 }}>
                                {formatDate(createdAt)}
                            </Typography>
                        }
                        <Rating
                            name="rating"
                            value={isLoading ? 5 : rating}
                            readOnly
                            precision={1}
                            sx={{ mb: 2 }}
                        />
                        <>
                            {isLoading ? (
                                <Skeleton variant="rectangular" width="100%" sx={{borderRadius: "4px"}} >
                                    <div style={{ paddingTop: '30%' }} />
                                </Skeleton>
                            ) :
                                <img
                                    src={`${api.defaults.baseURL}/${image}`}
                                    alt={`review-img`}
                                    style={{ width: '100%', height: 'auto' }}
                                />
                            }
                        </>


                    </Grid2>

                    <Grid2 item size={{xs: 12, sm: 12, md: 8, lg: 8}}>
                        <Typography variant="h4" gutterBottom>
                            Что больше всего понравилось?
                        </Typography>
                        {isLoading ? (
                                <Skeleton variant="rectangular" width="100%" sx={{borderRadius: "4px"}} >
                                    <div style={{ paddingTop: '10%' }} />
                                </Skeleton>
                        ) :
                            <Typography variant="desc" gutterBottom>
                                {intro}
                            </Typography>
                        }

                        <Typography variant="h4" gutterBottom>
                            Текст отзыва:
                        </Typography>
                        {isLoading ? (
                                <Skeleton variant="rectangular" width="100%" sx={{borderRadius: "4px"}} >
                                    <div style={{ paddingTop: '20%' }} />
                                </Skeleton>
                            ) :
                            <Typography variant="desc" gutterBottom>
                                {mainText}
                            </Typography>
                        }

                    </Grid2>
                </Grid2>
            </CardContent>
        </Card>
    )
}


export default ReviewCard;