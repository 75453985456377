import { useState } from "react";
import { Drawer, List, ListItem, ListItemButton, ListItemText,Collapse } from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Link } from "react-router-dom";

const MobileMenu = ({openMenu, onClick}) => {
    const [housesOpen, setHousesOpen] = useState(false);
    return (
            <Drawer anchor="left" open={openMenu} onClose={onClick(false)}>
                <List sx={{ width: 250 }}>
                    <ListItem disablePadding>
                        <ListItemButton component={Link} to="/" onClick={onClick(false)}>
                            <ListItemText primary="Главная" />
                        </ListItemButton>
                    </ListItem>

                    <ListItem disablePadding>
                        <ListItemButton onClick={() => setHousesOpen(!housesOpen)}>
                            <ListItemText primary="Дома" />
                            {housesOpen ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                    </ListItem>
                    <Collapse in={housesOpen} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItemButton component={Link} to="/houses?floors=1" onClick={onClick(false)}>
                                <ListItemText primary="Одноэтажные" />
                            </ListItemButton>
                            <ListItemButton component={Link} to="/houses?floors=2" onClick={onClick(false)}>
                                <ListItemText primary="Двухэтажные" />
                            </ListItemButton>
                            <ListItemButton component={Link} to="/houses?garage=true" onClick={onClick(false)}>
                                <ListItemText primary="С гаражом" />
                            </ListItemButton>
                            <ListItemButton component={Link} to="/houses?terrace=true" onClick={onClick(false)}>
                                <ListItemText primary="С террасой" />
                            </ListItemButton>
                        </List>
                    </Collapse>

                    <ListItem disablePadding>
                        <ListItemButton component={Link} to="/townhouses" onClick={onClick(false)}>
                            <ListItemText primary="Таунхаусы" />
                        </ListItemButton>
                    </ListItem>

                    <ListItem disablePadding>
                        <ListItemButton component={Link} to="/technology" onClick={onClick(false)}>
                            <ListItemText primary="О технологии" />
                        </ListItemButton>
                    </ListItem>

                    <ListItem disablePadding>
                        <ListItemButton component={Link} to="/about" onClick={onClick(false)}>
                            <ListItemText primary="О компании" />
                        </ListItemButton>
                    </ListItem>

                    <ListItem disablePadding>
                        <ListItemButton component={Link} to="/contact" onClick={onClick(false)}>
                            <ListItemText primary="Контакты" />
                        </ListItemButton>
                    </ListItem>
                </List>
            </Drawer>
    );
};

export default MobileMenu;
