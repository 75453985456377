import api from "./api";

class PortfolioService {
    static async getAll(query={}) {
        const params = new URLSearchParams();
        if (query.page) {
            params.append('page', query.page);
        }
        if (query.limit) {
            params.append('limit', query.limit);
        }
        const response = await api.get(`/api/portfolio/?${params.toString()}`);
        return response.data;
    }
    static async create(formData, token) {
        const response = await api.post(`/api/portfolio/`, formData, {
            headers: {
                "Content-Type": 'multipart/form-data',
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    }
    static async deleteById(id, token) {
        const response = await api.delete(
            `/api/portfolio/${id}`, {
                headers: {
                Authorization: `Bearer ${token}`
                }
            }
            );
        return response.data;
    }

}


export default PortfolioService;