import api from "./api";

class FormService {
    static async createCalcRequest(data){
        const response = await api.post("/api/requests/calc-form", data)
        return response.data;
    }
    static async createImageRequest(data){
        const response = await api.post("/api/requests/image-form", data,{
            headers: {
                'Content-Type': 'multipart/form-data'
            }})
        return response.data;
    }
    static async createHouseRequest(data){
        const response = await api.post("/api/requests/house-form", data)
        return response.data;
    }
    static async createSimpleRequest(data){
        const response = await api.post("/api/requests/simple-form", data)
        return response.data;
    }
}

export default FormService;