import api from "./api";

class RequestService {
    static async getAll(query={}, token) {
        const params = new URLSearchParams();
        if (query.request_type) {
            params.append('request_type', query.request_type);
        }
        if (query.page) {
            params.append('page', query.page);
        }
        if (query.limit) {
            params.append('limit', query.limit);
        }
        const response = await api.get(`/api/requests/?${params.toString()}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            })
        return response.data;
    }
    static async deleteById(id, token) {
        const response = await api.delete(`/api/requests/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        })
        return response.data;
    }
    static async update(id, query={}, token){
        const params = new URLSearchParams();
        if(query.checked) {
            params.append('checked', true);
        } else {
            params.append('checked', false);
        }
        const response = await api.patch(
            `/api/requests/${id}?${params.toString()}`, {},{
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            })
        return response.data
    }
}

export default RequestService;