import React from 'react';
import BaseCard from '../BaseCard';

function HouseCard({
                       item
                   }) {
    const parameters = [
        { label: 'Размер', value: item ?  `${item.size}` : null },
        { label: 'Площадь дома', value: item ? `${item.area}` : null},
        { label: 'Площадь террасы', value: item ? `${item.terrace_area}`: null },
        { label: 'Кол-во спален', value:item ? item.bedrooms : null },
    ];
    const title = item ? item.title : null;
    const price = item ? item.price : null;
    const imageURL = item ? item.main_image : null;
    const link = item ? `/houses/${item.id}` : null;
    const isLoading = !item;
    return (
        <BaseCard
            isLoading={isLoading}
            imageUrl={imageURL}
            title={title}
            price={price}
            parameters={parameters}
            link={link}
        />
    );
}

export default HouseCard;