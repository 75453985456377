import PanelService from "../../api/PanelService";
import BasePage from "./BasePage";

const PanelsAdminPage = () => {
    const panelFields = [
        { name: 'width', label: 'Ширина', type: 'number'},
        { name: 'height', label: 'Высота', type: 'number'},
        { name: 'length', label: 'Длина', type: 'number'},
        { name: 'density', label: 'Плотность', type: 'number'},
        { name: 'name', label: 'Название', type: 'text'},
        { name: 'price', label: 'Цена (99.99)', type: 'text'},

    ];
    return (
        <BasePage
            header={"Панели"}
            fields={panelFields}
            service={PanelService}
            requiredToken={true}
        />
    )
}
export default PanelsAdminPage