import {Box, Modal} from '@mui/material';
import FormService from "../../api/FormService";

import {schemaBase} from "../../schemas/schemaBase";
import BaseForm from "./BaseForm";
import api from "../../api/api";

const HouseForm = ({
                        entity,
                        open,
                        onClose
})=> {
    const mainImage = entity ? entity.main_image : undefined;
    const id = entity ? entity.id : undefined;
    const title = entity ? entity.title : undefined;
    const handleSuccess = async (data) => {
        const dataToSend = {
            client_name: data.clientName,
            phone: data.phone,
            house_id: id,
        };
        await FormService.createHouseRequest(dataToSend)
    };
    const imageURL = `${api.defaults.baseURL}/${mainImage}`;

    return (
        <Modal open={open} onClose={onClose}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%', left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: {xs: 300, sm: 500, md: 1200, },
                    maxWidth: '90%',
                    bgcolor: 'background.paper',
                    borderRadius: 1,
                }}
            >
                <BaseForm
                    image={imageURL}
                    onSubmitSuccess={handleSuccess}
                    schema={schemaBase}
                    title={`Отправьте заявку на дом ${title}`}
                    description={`Позже мы свяжемся с Вами для уточнения характеристик`}
                    />
            </Box>
        </Modal>

    );
}

export default HouseForm;
