import React from "react";
import HousePage from "../pages/HousePage";
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import ListHousePage from "../pages/ListHousePage";
import ListTownhousePage from "../pages/ListTownhousePage";
import HomePage from "../pages/HomePage";
import TownhousePage from "../pages/TownhousePage";
import AboutPage from "../pages/AboutPage";
import PolicyPage from "../pages/PolicyPage";
import PortfolioPage from "../pages/PortfolioPage";
import ContactPage from "../pages/ContactPage";
import ReviewsPage from "../pages/ReviewsPage";
import CreditPage from "../pages/CreditPage";
import TechnologyPage from "../pages/TechnologyPage";
import PayMethodsPage from "../pages/PayMethodsPage";
import GuaranteesPage from "../pages/GuaranteesPage";
import CalculatePage from "../pages/CalculatePage";
import ScrollHook from "../hooks/ScrollHook";
import PanelPage from "../pages/PanelPage";
import AdminPage from "../pages/AdminPage";
import LoginPage from "../pages/LoginPage";
import AdminLayout from "../Admin/AdminLayout";
import HousePageAdmin from "../Admin/Pages/HousePageAdmin";
import TownhousePageAdmin from "../Admin/Pages/TownhousePageAdmin";
import ReviewPageAdmin from "../Admin/Pages/ReviewPageAdmin";
import PortfolioPageAdmin from "../Admin/Pages/PortfolioPageAdmin";
import ImageReqPage from "../Admin/Pages/RequestPages/ImageReqPage";
import CalcReqPage from "../Admin/Pages/RequestPages/CalcReqPage";
import HouseReqPage from "../Admin/Pages/RequestPages/HouseReqPage";
import SimpleReqPage from "../Admin/Pages/RequestPages/SimpleReqPage";
import PanelsAdminPage from "../Admin/Pages/PanelsAdminPage";
import NotFoundPage from "../pages/NotFoundPage";

function PrivateRoute({ children }) {
    const token = localStorage.getItem('token');
    return token ? children : <Navigate to="/admin/login" replace />;
}
const AppRouter = () => {
    return (
        <Router>
                <ScrollHook />
                <Routes>
                    <Route path="/admin/login" element={<LoginPage />} />
                    <Route
                        path="/admin"
                        element={<PrivateRoute><AdminLayout/></PrivateRoute>}
                    >
                        <Route path={"houses"} element={<HousePageAdmin/>} />
                        <Route path={"townhouses"} element={<TownhousePageAdmin/>} />
                        <Route path={"reviews"} element={<ReviewPageAdmin/>} />
                        <Route path={"portfolio"} element={<PortfolioPageAdmin/>} />
                        <Route path={"panels"} element={<PanelsAdminPage/>} />
                        <Route path={"image-req"} element={<ImageReqPage/>} />
                        <Route path={"calc-req"} element={<CalcReqPage/>} />
                        <Route path={"simple-req"} element={<SimpleReqPage/>} />
                        <Route path={"house-req"} element={<HouseReqPage/>} />
                    </Route>
                    <Route path = '/houses' element={<ListHousePage />}/>
                    <Route path = '/' element={<HomePage />}/>
                    <Route path = '/houses/:id' element={<HousePage />}/>
                    <Route path = '/townhouses/:id' element={<TownhousePage />}/>
                    <Route path = '/townhouses' element={<ListTownhousePage />}/>
                    <Route path = '/panels' element={<PanelPage />}/>
                    <Route path = '/about' element={<AboutPage />}/>
                    <Route path = '/policy' element={<PolicyPage />}/>
                    <Route path = '/portfolio' element={<PortfolioPage />}/>
                    <Route path = '/contact' element={<ContactPage />}/>
                    <Route path = '/reviews' element={<ReviewsPage />}/>
                    <Route path = '/credit' element={<CreditPage />}/>
                    <Route path = '/technology' element={<TechnologyPage />}/>
                    <Route path = '/payments' element={<PayMethodsPage />}/>
                    <Route path = '/guarantees' element={<GuaranteesPage />}/>
                    <Route path = '/calculate' element={<CalculatePage />}/>
                    <Route path = '/admin' element={<AdminPage />}/>
                    <Route path="/admin" element={<Navigate to="/admin/houses" replace />} />
                    <Route path="*" element={<NotFoundPage />} />
                </Routes>
        </Router>
    )
}

export default AppRouter