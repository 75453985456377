import Header from './Header';
import Footer from './Footer';
import NavBar from "./NavBar";
import HeroBlock from "./HeroBlock";
import DefaultBox from "../UI/DefaultBox";

function Layout({
                    children,
                    heroImage,
                    heroContent
}) {
    return (
        <>
            <Header />
            <NavBar/>
            {(heroImage || heroContent) && (
                <HeroBlock
                    imageUrl={heroImage}
                    content={heroContent}
                />
            )}

            <DefaultBox sx={{ width: {md: '80%', lg: '80%', sm: '80%', xs: '80%'}, minHeight: '30vh', py: 0 }}>
                {children}
            </DefaultBox>

            <Footer />
        </>
    );
}

export default Layout;
