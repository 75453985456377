export const formatText = (text) => {
    return text.split("\n").map((line, index) => (
        <>
            {line.split(/(\*\*.*?\*\*)/g).map((part, idx) =>
                part.startsWith("**") && part.endsWith("**") ? (
                    <strong key={idx}>{part.slice(2, -2)}</strong>
                ) : (
                    part
                )
            )}
            <br />
        </>
    ));
};