import {Box, Modal} from "@mui/material";
import React from "react";

const ModalImage = ({open, imageUrl, onClose}) => {
    return (
        <Modal open={open} onClose={onClose}>
            <Box sx={{
                position: 'absolute',
                top: '50%', left: '50%',
                transform: 'translate(-50%, -50%)',
                maxWidth: '90%',
                maxHeight: '90%'
            }}>
                <img src={imageUrl} alt="big" style={{ width: '100%', height: 'auto' }} />
            </Box>
        </Modal>
    )
}

export default ModalImage;