import {Box, Table, TableBody, TableCell, TableHead, TableRow, Typography} from "@mui/material";
import React from "react";
import {infoTableValues} from "../consts/consts";

const InfoTable = () => {
    return (
        <Box sx={{ overflowX: 'auto' }}>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>
                        <Typography variant="subtitle1" fontWeight="bold">
                            Комплектация
                        </Typography>
                    </TableCell>
                    <TableCell>
                        <Typography variant="subtitle1" fontWeight="bold">
                            Комплект дома
                        </Typography>
                    </TableCell>
                    <TableCell>
                        <Typography variant="subtitle1" fontWeight="bold">
                            Комплект + монтаж
                        </Typography>
                    </TableCell>
                    <TableCell>
                        <Typography variant="subtitle1" fontWeight="bold">
                            Под ключ
                        </Typography>
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {infoTableValues.map((row, idx) => {
                    return (
                        <TableRow key={idx}>
                            {row.map((cell, cellIndex)=> (
                                <TableCell key={cellIndex}>{cell}</TableCell>
                            ))}
                        </TableRow>
                        )

                })
                }
            </TableBody>
        </Table>
        </Box>
    )
}
export default InfoTable;