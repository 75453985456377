import {Typography} from "@mui/material";
import React from "react";
import Layout from "../components/layout/Layout";
import HeroContent from "../components/layout/HeroContent";
import DefaultBox from "../components/UI/DefaultBox";
import {creditText} from "../consts/texts";
import {Helmet} from "react-helmet";

const CreditPage = () => {
    const heroContent =
        <HeroContent
            header={"Кредит и ипотека"}
        />;
    return (
        <Layout heroContent={heroContent}>
            <Helmet><title>Кредит и ипотека</title></Helmet>

            <DefaultBox>
                <Typography variant="desc">
                    {creditText}
                </Typography>
            </DefaultBox>
        </Layout>

    )
}

export default CreditPage