import BaseRequestAdmin from "./BaseRequestAdmin";

const SimpleReqPage = () => {
    return (
        <BaseRequestAdmin
            fields={[]}
            request_type={'CONSULTATION'}
            header={"Консультация"}
        />
    )
}
export default SimpleReqPage;