import {Grid2, Typography} from "@mui/material";
import React from "react";

const FooterColumn = ({title, children}) => {
    return (
        <Grid2 item size={{xs: 12, sm: 12, md: 2, lg:2}}>
            <Typography variant="ch6"  gutterBottom>
                {title}
            </Typography>
            {children}
        </Grid2>
    )
}

export default FooterColumn;