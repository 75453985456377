import {Grid2, Typography} from '@mui/material';
import DefaultBox from "../UI/DefaultBox";
import LinksList from "../UI/LinksList";
import FooterColumn from "../UI/FooterColumn";
import RefButton from "../UI/Button/RefButton";
import {housesLinks, townhousesLinks, companyLinks, clientsLinks, addLinks} from "../../consts/links";
function Footer() {
    return (
        <DefaultBox sx={{ backgroundColor: (theme) => theme.palette.primary.footer }}>
            <DefaultBox sx={{ width: '80%', py: 0}}>
                <Grid2 container spacing={2}>
                    <FooterColumn title={"Дома"}>
                        <LinksList list={housesLinks}/>
                    </FooterColumn>
                    <FooterColumn title={"Таунхаусы"}>
                        <LinksList list={townhousesLinks}/>
                    </FooterColumn>
                    <FooterColumn title={"Компания"}>
                        <LinksList list={companyLinks}/>
                    </FooterColumn>
                    <FooterColumn title={"Клиентам"}>
                        <LinksList list={clientsLinks}/>
                    </FooterColumn>
                    <FooterColumn title={"Дополнительно"}>
                        <LinksList list={addLinks}/>
                    </FooterColumn>
                    <FooterColumn title={"Контакты"}>
                        <Typography variant="body3" sx={{ display: 'block', my: 1 }}>
                            г. Москва и МО
                        </Typography>
                        <Typography variant="body3" sx={{ display: 'block', mb: 1 }}>
                            Пн-Вс: 9:00 - 18:00
                        </Typography>

                        <RefButton title={"+7 (966) 171-67-61"} href={"tel:+79661716761"} variant="body3" sx/>
                        <RefButton title={"housesip@yandex.ru"} href={"mailto:housesip@yandex.ru"} variant="body3"/>

                    </FooterColumn>
                </Grid2>

                <DefaultBox sx={{ textAlign: 'center', mt: 4, py: 0 }}>
                    <Typography variant="body4">
                        © 2025 SIP Houses. Все права защищены.
                    </Typography>
                </DefaultBox>
            </DefaultBox>
        </DefaultBox>
    );
}

export default Footer;
