
import {Grid2} from "@mui/material";

const GridItemNavBar = ({
                            children,
                            size={ xs: 0, md: 2 },
                            display={ xs: 'none', md: 'flex' },
                            align="center"
}) => {
    return (
        <Grid2
            item
            size={size}
            sx={{
                display: display,
                alignItems: align,
                justifyContent: align,
            }}
        >
            {children}

        </Grid2>
    )
}
export default GridItemNavBar;