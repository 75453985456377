import { Grid2, Typography} from "@mui/material";
import React from "react";
import TownhouseCard from "../TownhouseCard";
import HouseCard from "../HouseCard";
import DefaultBox from "../UI/DefaultBox";

const WatchMore = ({
                       related,
                        isLoading,
    CardComponent,

}) => {
    return (
        <DefaultBox >
            <Typography variant="h4">Смотрите также</Typography>
                <Grid2 container spacing={2} alignItems="stretch" sx={{mt: 2}}>
                    {(isLoading ? Array.from(new Array(3)) : related).map((item, index) => (
                        <Grid2 item size={{xs: 12, sm: 12, md: 4, lg: 4}} sx={{display: "flex"}}  key={index}>
                            <CardComponent item={item}/>
                        </Grid2>
                    ))}
                </Grid2>

        </DefaultBox>
    )
}


export default WatchMore;