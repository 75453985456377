import {Menu, MenuItem} from "@mui/material";
import {Link} from "react-router-dom";

const DroppedMenu = ({
    handleClose,
    anchorEl,
    links
                     }) => {
    return (
        <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            slotProps={{paper: {onMouseLeave: handleClose}}}
            disableScrollLock
            sx={{
                mt: 2
            }}

        >
            {links.map((item, index) => (
                <MenuItem component={Link} to={item.link} onClick={handleClose} key={index}>
                    {item.title}
                </MenuItem>
            ))}

        </Menu>
    )
}

export default DroppedMenu;
