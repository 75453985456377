import React, { useState, useEffect } from 'react';
import { useParams} from 'react-router-dom';
import {useFetching} from "../../hooks/useFetching";
import CategoryBox from "../CategoryBox";
import WatchMore from "./WatchMore";
import CompanyInfo from "../CompanyInfo";
import EntityInfo from "./EntityInfo";
import ModalImage from "../UI/ModalImage";
import Main from "./Main";
import HouseForm from "../Form/HouseForm";
import SimpleForm from "../Form/SimpleForm";
import Layout from "../layout/Layout";
import api from "../../api/api";
import HeroContent from "../layout/HeroContent";
import DefaultBox from "../UI/DefaultBox";
import ClickButton from "../UI/Button/ClickButton";
import {Button, Skeleton, Typography} from "@mui/material";
import HouseCard from "../HouseCard";
import Error from "../Error/Error"
import {Helmet} from "react-helmet";

const  BaseDetail = ({
                         isHouse=false,
                         service,
                         mapData,
                     }) =>  {
    const { id } = useParams();
    const [entity, setEntity] = useState(null);
    const [related, setRelated] = useState([]);

    const [fetchEntity, isEntityLoading, entityErrors] = useFetching(
        async () => {
            const entityRaw = await service.getById(id);
            const entityData = mapData(entityRaw);
            setEntity(entityData);
        }
    )
    const [fetchRelated, isRelatedLoading, relatedErrors] = useFetching(
        async () => {
            if (!entity) return;
            const limit = 3
            const related = await service.getRelated(entity, limit);
            setRelated(related);
        }
    )
    const [openRequest, setOpenRequest] = useState(false);
    const handleOpenRequest = () => setOpenRequest(true);
    const handleCloseRequest = () => setOpenRequest(false);

    const handlePhotoClick = (url) => {
        setModalImage(url);
        setOpenModal(true);
    };
    const [openModal, setOpenModal] = useState(false);
    const [modalImage, setModalImage] = useState('');
    const helmet = isHouse ? "Дом из СИП-панелей" : "Таунхаус из СИП-панелей"
    useEffect(() => {
        fetchEntity();
    }, [id]);
    useEffect(() => {
        if (entity && !entityErrors){
            fetchRelated();
        }
    }, [entity, entityErrors]);
    const heroContent = entity && !isEntityLoading && !entityErrors ?
        <HeroContent
            img={true}
            header={
                `${isHouse ? "Дом из СИП-панелей" : "Таунхаус из СИП-панелей"} ${entity.title}`
            }
            price={entity.price}

        />
    :
        <HeroContent element={
            <Skeleton><Typography variant="h2">Дом из СИП-панелей</Typography></Skeleton>
        }/>
    return (
        <Layout
            heroContent={heroContent}
            heroImage={
                entity && !isEntityLoading && !entityErrors
                    ?
                        `${api.defaults.baseURL}/${entity.main_image}`
                    :
                        null
            }
        >
            <Helmet>
                <title>{
                entity ? entity.title : helmet
                }
                </title>
            </Helmet>
            {entityErrors && <Error error={entityErrors} />}
            {!entityErrors && (
                <DefaultBox>
                    <EntityInfo
                        entity={entity}
                        isLoading={isEntityLoading}
                    />
                    <Main
                        onPhotoClick={handlePhotoClick}
                        entity={entity}
                        isLoading={isEntityLoading}
                    >
                        {isHouse && isEntityLoading ?
                            <DefaultBox>
                                <Skeleton width="100%" >
                                    <Button
                                        fullWidth
                                        sx={{
                                            px: 0,
                                            textTransform: 'none',
                                            typography: "h4"
                                        }}>.</Button>
                                </Skeleton>
                            </DefaultBox>
                            :
                            <DefaultBox>
                                <ClickButton handleOpen={handleOpenRequest} title={"Отправить заявку"}/>
                                <HouseForm
                                    entity={entity}
                                    onClose={handleCloseRequest}
                                    open={openRequest}
                                />
                            </DefaultBox>
                        }
                    </Main>
                    <ModalImage
                        open={openModal}
                        onClose={() => setOpenModal(false)}
                        imageUrl={modalImage}
                    />

                    <CompanyInfo />
                    <SimpleForm/>


                    {relatedErrors && (
                        <Error error={relatedErrors} />
                    )}
                    {isHouse && (
                        <WatchMore
                            related={related}
                            CardComponent={HouseCard}
                            isLoading={isRelatedLoading}
                        />
                    )}

                    {isHouse && <CategoryBox />}

                </DefaultBox>
            )}

        </Layout>

    );
}

export default BaseDetail;
