import api from "./api";

class PanelService {
    static async getAll(token, query={}){
        const params = new URLSearchParams();
        if (query.page) {
            params.append('page', query.page);
        }
        if (query.limit) {
            params.append('limit', query.limit);
        }
        const response = await api.get(`/api/panels?${params.toString()}`,
            {headers: {
                Authorization: `Bearer ${token}`
                }},
            )
        return response.data
    }
    static async getGroupedPanels(){
        const response = await api.get("/api/panels/grouped")
        return response.data
    }
    static async deleteById(id, token) {
        const response = await api.delete(`/api/panels/${id}`,
            {headers: {
                    Authorization: `Bearer ${token}`
                }});
        return response.data;
    }

    static async update(id, formData, token){
        const response = await api.patch(
            `/api/panels/${id}`,
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${token}`
                },
            });
        return response.data
    }
    static async create(formData, token){
        const response = await api.post(
            `/api/panels/`, formData,{
                headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${token}` },
            })
        return response.data
    }
}

export default PanelService;