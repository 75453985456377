import {Box, Card, Grid2, Typography} from "@mui/material";
import React from "react";
import {Link} from "react-router-dom";
import DefaultBox from "./UI/DefaultBox";
import ImageCard from "./UI/ImageCard";
const projectCategories = [
    { id: 1, title: 'Одноэтажные дома', link: '/houses?floors=1', image: '/images/odnoetazhnyj-dom.jpg' },
    { id: 2, title: 'Двухэтажные дома', link: '/houses?floors=2', image: '/images/dvuhetazhnyj-dom.jpg' },
    { id: 3, title: 'Дома с террасой', link: '/houses?terrace=true', image: '/images/dom-s-terrasoj.jpg' },
    { id: 4, title: 'Дома с гаражом', link: '/houses?garage=true', image: '/images/dom-s-garazhom.jpg' },
];
const CategoryBox = () => {
    return (
        <DefaultBox>
            <Typography variant="ch3" gutterBottom>
                Проекты домов
            </Typography>
            <Grid2 container spacing={2}>
                {projectCategories.map((cat) => (
                    <Grid2 item size={{xs: 12, sm: 12, md: 6, lg: 6}} key={cat.id}>
                        <Card sx={{ height: '100%'}}>
                            <Box sx={{ position: 'relative', cursor: 'pointer'}} component={Link} to={cat.link}>
                                <ImageCard path={cat.image} title={cat.title}/>
                            </Box>
                        </Card>
                    </Grid2>
                ))}
            </Grid2>
        </DefaultBox>
    )
}

export default CategoryBox