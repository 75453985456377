import {Box, Card, CardMedia, Skeleton} from "@mui/material";
import api from "../../api/api";
import React from "react";

const PortfolioImage = ({item, handleClick}) => {
    if (!item) {
        return (
                <Skeleton sx={{height: 200}} animation="wave" variant="rectangular"/>
        )
    }
    return (
            <CardMedia
                component="img"
                image={`${api.defaults.baseURL}/${item.image_path}`}
                onClick={()=>handleClick(`${api.defaults.baseURL}/${item.image_path}`)}
                alt={`portfolio-${item.id}`}
                sx={{ height: 200, objectFit: 'cover' }}
            />
    )
}

export default PortfolioImage;