
import HouseService from "../../api/HouseService";
import BasePage from "./BasePage";

const HousePageAdmin = () => {
    const houseFields = [
        { name: 'title', label: 'Название', type: 'text'},
        { name: 'price', label: 'Цена', type: 'number'},
        { name: 'size', label: 'Размер (10х10)', type: 'text'},
        { name: 'area', label: 'Площадь', type: 'number'},
        { name: 'bedrooms', label: 'Кол-во спален', type: 'number'},
        { name: 'terrace_area', label: 'Площадь террасы', type: 'number'},
        { name: 'description', label: 'Описание', type: 'text'},
        { name: 'terrace', label: 'Терраса', type: 'switch' },
        { name: 'garage', label: 'Гараж', type: 'switch' },
        { name: 'floors', label: 'Кол-во этажей', type: 'number'},
        { name: 'main_image', label: 'Основное изображение', type: 'file'},
        { name: 'other_images', label: 'Другие изображения', type: 'file', multiple: true },
    ];
    return (
        <BasePage
            header={"Дома"}
            fields={houseFields}
            service={HouseService}
        />
    )
}

export default HousePageAdmin