import * as Yup from "yup";
import { baseFieldsSchema } from "./baseFields";

export const schemaWithArea = Yup.object().shape({
    ...baseFieldsSchema,
    area: Yup.string()
        .matches(/^\d+$/, "Только цифры")
        .test("maxNumber", "Площадь не должна превышать 1000", (value) => {
            if (!value) return true;
            const num = parseInt(value, 10);
            return num <= 1000;
        })
        .required("Укажите площадь"),
    checkboxes: Yup.array()
       .of(Yup.string())
});