import {TableCell, Tooltip, Typography} from "@mui/material";
import api from "../api/api";
import React from "react";

const CustomCell = ({isLoading, item, col}) => {
    if(isLoading) {
        return (
            <TableCell>
            <Typography>Значение</Typography>
            </TableCell>
        )
    }
    if (col.name === "description") {
        return (
            <TableCell sx={{ maxWidth: 100, overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                <Tooltip title={item[col.name]} placement="bottom-start">
                    <span>{item[col.name]}</span>
                </Tooltip>
            </TableCell>
        )
    }
    if (col.name === "main_image" || col.name === "image" || col.name === "image_path") {
        return (
            <TableCell>
                <img
                    src={`${api.defaults.baseURL}/${item[col.name]}`}
                    style={{maxWidth: "50px", height: "auto", objectFit: "cover" }}
                />
            </TableCell>
        )
    }
    if (col.name === "other_images") {
        return (
            <TableCell>
                {item[col.name].map((img, index)=>(
                        <img
                            src={`${api.defaults.baseURL}/${img}`}
                            style={{width: "40px", height: "40px", marginRight: "5px", objectFit: "cover"}}
                            key={index}
                        />
                    )
                )
                }
            </TableCell>
        )
    }
    return (
        <TableCell>
            {item[col.name]}
        </TableCell>
    )
}

export default CustomCell