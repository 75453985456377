import {Box, Modal} from '@mui/material'
const ModalForm = ({children, open, onClose}) => {
    return (
        <Modal open={open} onClose={onClose}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%', left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: {md: 500,lg: 500, sm: 500, xs: 300},
                    maxWidth: '90%',
                    backgroundColor: (theme) => theme.palette.primary.bgForm,
                    borderRadius: 1,
                    p: 3,
                    maxHeight: '90vh',
                    overflowY: 'auto',
                }}
            >
                {children}
            </Box>
        </Modal>
    )
}
export default ModalForm