import {Box, Grid2, Skeleton, Typography} from "@mui/material";
import React from "react";
import api from "../../api/api";
import DefaultBox from "../UI/DefaultBox";

const Main = ({
                  isLoading,
                  entity,
                  onPhotoClick=null,
                  children,
}) => {
    const description = entity ? entity.description : null;
    const mainImage = entity ? entity.main_image : null;
    const images = entity ? entity.other_images : [];
    return (
        <DefaultBox>
            <Grid2 container spacing={4} sx={{flexDirection: { xs: "column-reverse", md: "row" }}}>
                <Grid2 item size={{ xs: 12, sm: 12, md: 6, lg: 6 }}>
                    <Typography variant="h3">О проекте</Typography>
                    {isLoading ?
                            <Skeleton variant="rectangular" width="100%" sx={{borderRadius: "4px"}} >
                                <div style={{ paddingTop: '60%' }} />
                            </Skeleton>
                        :
                        <Typography variant="desc" sx={{ mt: 2 }}>{description}</Typography>
                    }
                    {children}
                </Grid2>

                <Grid2
                    item
                    size={{ xs: 12, sm: 12, md: 6 }}
                >

                        {isLoading ?
                            <Skeleton variant="rectangular" width="100%" sx={{borderRadius: "4px"}}>
                                <div style={{ paddingTop: '70%' }} />
                            </Skeleton>
                            :
                            <Box sx={{ mb: 2 }}>
                            <img
                                src={`${api.defaults.baseURL}/${mainImage}`}
                                alt="img_main"
                                style={{ width: '100%', cursor: 'pointer' }}
                                onClick={() => onPhotoClick(`${api.defaults.baseURL}/${mainImage}`)}
                            />
                            </Box>
                        }

                    <Grid2 container spacing={2} alignItems="stretch">
                        {(isLoading ? Array.from(new Array(3)) : images).map((item, index) => (
                            <Grid2
                                item
                                size={{ xs: 4 }}
                                key={index}
                                sx={{ display: "flex", alignItems: "stretch" }}
                            >
                                {isLoading ?
                                    <Skeleton  width="100%" sx={{height: {xs: 100, md: 200}}} />
                                    :
                                    <img
                                        src={`${api.defaults.baseURL}/${item}`}
                                        alt={`photo ${index}`}
                                        style={{
                                            width: "100%",
                                            height: "100%",
                                            objectFit: "cover",
                                            cursor: "pointer",
                                        }}
                                        onClick={() => onPhotoClick(`${api.defaults.baseURL}/${item}`)}
                                    />
                                }

                            </Grid2>
                        ))}

                    </Grid2>
                </Grid2>
            </Grid2>

        </DefaultBox>
    )
}

export default Main;