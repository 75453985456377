export const technologyText = [
    {
        title: 'СИП-панели из ОСП',
        text: 'Технология строительства из СИП-панелей ОСП представляет собой современный и эффективный метод возведения зданий, который ориентирован на экологичность, энергоэффективность и простоту конструкции. СИП-панели (структурно-изолирующие панели) из ориентированно-стружечных плит (ОСП) состоят из двух слоев ОСП-плит, между которыми расположен слой изоляционного материала, чаще всего пенополистирола. Эта комбинация материалов обеспечивает высокие теплоизоляционные свойства, что делает здания, построенные по этой технологии, весьма экономичными в плане энергопотребления.\n' +
            ' \n' +
            '\n' +
            'Основные преимущества строительства домов из СИП-панелей ОСП следующие:\n' +
            '1. **Экологичность**: Использование ОСП-3 в конструкции СИП-панелей вызывает определенные споры в отношении их экологичности. Несмотря на это, материалы, используемые в СИП-панелях, в целом способствуют созданию более здорового и безопасного жилого пространства, поскольку не содержат формальдегидных смол и могут применяться как для внешней отделки, так и для внутренних работ.\n' +
            '2. **Энергоэффективность**: Благодаря уникальным теплоизолирующим свойствам материалов, здания из СИП-панелей ОСП требуют меньше энергии на отопление и охлаждение, что позволяет снизить эксплуатационные расходы.\n' +
            '3. **Быстрота и простота монтажа**: Конструкция из СИП-панелей позволяет значительно ускорить процесс строительства. Панели легко соединяются между собой, что минимизирует время на возведение каркаса здания.\n' +
            '4. **Высокая несущая способность**: Несмотря на свою легкость, СИП-панели обладают высокой прочностью и способны выдерживать значительные нагрузки, что делает их идеальным решением для конструкции стен, перекрытий и крыши.\n' +
            ' \n' +
            '\n' +
            'Таким образом, использование СИП-панелей ОСП в строительстве позволяет создавать долговечные, надежные и экологически чистые здания, адаптированные к суровым климатическим условиям, обладающие отличными теплоизоляционными характеристиками и в то же время экономичные в эксплуатации.'
    },
    {
        title: 'СИП-панели из ЦСП',
        text: 'Технология строительства из СИП-панелей ЦСП (цементно-стружечных плит) представляет собой эффективный метод создания жилых и нежилых зданий, который обеспечивает отличную изоляцию и устойчивость сооружения. СИП-панели из ЦСП состоят из древесной стружки, портландцемента и минеральных добавок, формируя таким образом прочный и устойчивый к внешним условиям конструкционный материал. Размеры строительных листов ЦСП могут варьироваться, но стандартные размеры составляют 2700×1250 мм и 3200×1250 мм, с различной толщиной плиты.\n' +
            ' \n' +
            'Преимущества строительства из СИП-панелей ЦСП включают в себя:\n' +
            '1. **Экологичность**: Производство ЦСП не включает использование формальдегидных смол, что делает этот материал безопасным для окружающей среды и для жильцов. В отличие от ОСП-3 плит, которые вызывают определенные споры касаемо экологичности, ЦСП предлагает более экологически чистую альтернативу.\n' +
            '2. **Прочность и долговечность**: Состав ЦСП обеспечивает высокую прочность материала, благодаря чему сооружения имеют увеличенный срок службы и способность противостоять внешним факторам.\n' +
            '3. **Теплоизоляция**: ЦСП обладают отличными теплоизоляционными свойствами, что обеспечивает снижение теплопотерь здания и, как следствие, экономию на отоплении.\n' +
            '4. **Универсальность**: Возможность использования ЦСП как для внешней отделки, так и для внутренних работ делает этот материал универсальным решением для различных строительных задач.\n' +
            '5. **Ускоренное строительство**: Благодаря стандартным размерам и легкости монтажа СИП-панелей из ЦСП, строительный процесс существенно ускоряется по сравнению с традиционными методами.\n' +
            ' \n' +
            'Компания SIP Houses, которая использует ЦСП от производителя Тамак для своих СИП-панелей, является одним из примеров компаний, внедряющих эту технологию в строительство. Уточнить цены и возможные комплектации можно непосредственно у компании.\n' +
            ' \n' +
            'Вот как можно описать СИП-дома из ЦСП с использованием предоставленного датасета:\n' +
            'СИП-дом из ЦСП — это здание, построенное с применением цементно-стружечных плит, с особым уклоном на экологичность и энергоэффективность, предоставляющее удобство в возведении конструкции и обладающее усиленной теплоизоляцией и прочностью.'
    }
]

export const aboutText = [
    {
        title: 'SIP Houses',
        text: 'Наша производственная линия СИП-панелей и домокомплектов использует станки 3-го поколения, что гарантирует высочайшее качество наших продуктов. В процессе производства применяются инновационные технологии и материалы, которые обеспечивают экологичность, долговечность и высокую энергоэффективность готовой продукции.\n' +
            ' \n' +
            '\n' +
            'Станки 3-го поколения позволяют нам осуществлять точное и быстрое производство СИП-панелей со строгим соблюдением всех технических параметров. Это ведет к уменьшению отходов материалов и повышению общей продуктивности на производстве.\n' +
            ' \n' +
            '\n' +
            'Производство домокомплектов на таком оборудовании дает нам возможность предложить нашим клиентам готовые решения для строительства домов, которые отлично подходят как для частного строительства, так и для масштабных жилищных проектов. Благодаря высокой автоматизации и контролю качества на каждом этапе производства, мы гарантируем, что наши домокомплекты соответствуют всем современным стандартам и требованиям.\n' +
            ' \n' +
            '\n' +
            'Мы гордимся тем, что наше производство способствует улучшению качества жизни людей, предоставляя им возможность жить в безопасных, энергоэффективных и экологически чистых домах.',
        photo: 'images/about_image.jpg'
    },
    {
        title: 'О производстве',
        text: 'Наша производственная линия СИП-панелей и домокомплектов использует станки 3-го поколения, что гарантирует высочайшее качество наших продуктов. В процессе производства применяются инновационные технологии и материалы, которые обеспечивают экологичность, долговечность и высокую энергоэффективность готовой продукции.\n' +
            ' \n' +
            '\n' +
            'Станки 3-го поколения позволяют нам осуществлять точное и быстрое производство СИП-панелей со строгим соблюдением всех технических параметров. Это ведет к уменьшению отходов материалов и повышению общей продуктивности на производстве.\n' +
            ' \n' +
            '\n' +
            'Производство домокомплектов на таком оборудовании дает нам возможность предложить нашим клиентам готовые решения для строительства домов, которые отлично подходят как для частного строительства, так и для масштабных жилищных проектов. Благодаря высокой автоматизации и контролю качества на каждом этапе производства, мы гарантируем, что наши домокомплекты соответствуют всем современным стандартам и требованиям.\n' +
            ' \n' +
            '\n' +
            'Мы гордимся тем, что наше производство способствует улучшению качества жизни людей, предоставляя им возможность жить в безопасных, энергоэффективных и экологически чистых домах.',
        photo: 'images/prod_image.jpg'
    }
]

export const creditText = 'В нашей компании, SIP Houses, мы предоставляем нашим клиентам уникальную возможность воплотить в жизнь мечту о собственном доме, даже если в настоящий момент вы не располагаете необходимой суммой. Мы сотрудничаем с ведущими банками, которые являются нашими долгосрочными партнерами. Это позволяет нашим клиентам воспользоваться кредитными и ипотечными программами на выгодных условиях, идеально подходящих под потребности в строительстве дома из качественных СИП-панелей. Выбирая нас, вы выбираете не только качество и надежность, но и доступность своего будущего дома.'
export const paymentsText = 'Мы в SIP Houses стремимся облегчить процесс приобретения вашего идеального дома, оттого предлагаем максимально удобные условия оплаты. В нашей компании принимаются все способы оплаты как от физических лиц, так и от юридических. Мы гарантируем гибкий подход к каждому клиенту, обеспечивая возможность выбрать самую подходящую форму оплаты.\n' +
    '\n' +
    'В нашей компании, SIP Houses, мы предоставляем нашим клиентам уникальную возможность воплотить в жизнь мечту о собственном доме, даже если в настоящий момент вы не располагаете необходимой суммой. Мы сотрудничаем с ведущими банками, которые являются нашими долгосрочными партнерами. Это позволяет нашим клиентам воспользоваться кредитными и ипотечными программами на выгодных условиях, идеально подходящих под потребности в строительстве дома из качественных СИП-панелей. Выбирая нас, вы выбираете не только качество и надежность, но и доступность своего будущего дома.'
export const policyText = {
    title : 'Политика в отношении обработки персональных данных',
    text: '**1. Общие положения**\n' +
        'Настоящая политика обработки персональных данных составлена в соответствии с требованиями Федерального закона от 27.07.2006. № 152-ФЗ «О персональных данных» (далее — Закон о персональных данных) и определяет порядок обработки персональных данных и меры по обеспечению безопасности персональных данных, предпринимаемые ООО «SIP Houses» (далее — Оператор).\n' +
        '\n' +
        '1.1. Оператор ставит своей важнейшей целью и условием осуществления своей деятельности соблюдение прав и свобод человека и гражданина при обработке его персональных данных, в том числе защиты прав на неприкосновенность частной жизни, личную и семейную тайну.\n' +
        '\n' +
        '1.2. Настоящая политика Оператора в отношении обработки персональных данных (далее — Политика) применяется ко всей информации, которую Оператор может получить о посетителях веб-сайта https://housesip.ru.\n' +
        '\n' +
        '**2. Основные понятия, используемые в Политике**\n' +
        '2.1. Автоматизированная обработка персональных данных — обработка персональных данных с помощью средств вычислительной техники.\n' +
        '\n' +
        '2.2. Блокирование персональных данных — временное прекращение обработки персональных данных (за исключением случаев, если обработка необходима для уточнения персональных данных).\n' +
        '\n' +
        '2.3. Веб-сайт — совокупность графических и информационных материалов, а также программ для ЭВМ и баз данных, обеспечивающих их доступность в сети интернет по сетевому адресу https://housesip.ru.\n' +
        '\n' +
        '2.4. Информационная система персональных данных — совокупность содержащихся в базах данных персональных данных и обеспечивающих их обработку информационных технологий и технических средств.\n' +
        '\n' +
        '2.5. Обезличивание персональных данных — действия, в результате которых невозможно определить без использования дополнительной информации принадлежность персональных данных конкретному Пользователю или иному субъекту персональных данных.\n' +
        '\n' +
        '2.6. Обработка персональных данных — любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных.\n' +
        '\n' +
        '2.7. Оператор — государственный орган, муниципальный орган, юридическое или физическое лицо, самостоятельно или совместно с другими лицами организующие и/или осуществляющие обработку персональных данных, а также определяющие цели обработки персональных данных, состав персональных данных, подлежащих обработке, действия (операции), совершаемые с персональными данными.\n' +
        '\n' +
        '2.8. Персональные данные — любая информация, относящаяся прямо или косвенно к определенному или определяемому Пользователю веб-сайта https://housesip.ru.\n' +
        '\n' +
        '2.9. Персональные данные, разрешенные субъектом персональных данных для распространения, — персональные данные, доступ неограниченного круга лиц к которым предоставлен субъектом персональных данных путем дачи согласия на обработку персональных данных, разрешенных субъектом персональных данных для распространения в порядке, предусмотренном Законом о персональных данных (далее — персональные данные, разрешенные для распространения).\n' +
        '\n' +
        '2.10. Пользователь — любой посетитель веб-сайта https://housesip.ru.\n' +
        '\n' +
        '2.11. Предоставление персональных данных — действия, направленные на раскрытие персональных данных определенному лицу или определенному кругу лиц.\n' +
        '\n' +
        '2.12. Распространение персональных данных — любые действия, направленные на раскрытие персональных данных неопределенному кругу лиц (передача персональных данных) или на ознакомление с персональными данными неограниченного круга лиц, в том числе обнародование персональных данных в средствах массовой информации, размещение в информационно-телекоммуникационных сетях или предоставление доступа к персональным данным каким-либо иным способом.\n' +
        '\n' +
        '2.13. Трансграничная передача персональных данных — передача персональных данных на территорию иностранного государства органу власти иностранного государства, иностранному физическому или иностранному юридическому лицу.\n' +
        '\n' +
        '2.14. Уничтожение персональных данных — любые действия, в результате которых персональные данные уничтожаются безвозвратно с невозможностью дальнейшего восстановления содержания персональных данных в информационной системе персональных данных и/или уничтожаются материальные носители персональных данных.\n' +
        '\n' +
        '**3. Основные права и обязанности Оператора**\n' +
        '3.1. Оператор имеет право:\n' +
        '\n' +
        '— получать от субъекта персональных данных достоверные информацию и/или документы, содержащие персональные данные;\n' +
        '\n' +
        '— в случае отзыва субъектом персональных данных согласия на обработку персональных данных, а также, направления обращения с требованием о прекращении обработки персональных данных, Оператор вправе продолжить обработку персональных данных без согласия субъекта персональных данных при наличии оснований, указанных в Законе о персональных данных;\n' +
        '\n' +
        '— самостоятельно определять состав и перечень мер, необходимых и достаточных для обеспечения выполнения обязанностей, предусмотренных Законом о персональных данных и принятыми в соответствии с ним нормативными правовыми актами, если иное не предусмотрено Законом о персональных данных или другими федеральными законами.\n' +
        '\n' +
        '3.2. Оператор обязан:\n' +
        '\n' +
        '— предоставлять субъекту персональных данных по его просьбе информацию, касающуюся обработки его персональных данных;\n' +
        '\n' +
        '— организовывать обработку персональных данных в порядке, установленном действующим законодательством РФ;\n' +
        '\n' +
        '— отвечать на обращения и запросы субъектов персональных данных и их законных представителей в соответствии с требованиями Закона о персональных данных;\n' +
        '\n' +
        '— сообщать в уполномоченный орган по защите прав субъектов персональных данных по запросу этого органа необходимую информацию в течение 10 дней с даты получения такого запроса;\n' +
        '\n' +
        '— публиковать или иным образом обеспечивать неограниченный доступ к настоящей Политике в отношении обработки персональных данных;\n' +
        '\n' +
        '— принимать правовые, организационные и технические меры для защиты персональных данных от неправомерного или случайного доступа к ним, уничтожения, изменения, блокирования, копирования, предоставления, распространения персональных данных, а также от иных неправомерных действий в отношении персональных данных;\n' +
        '\n' +
        '— прекратить передачу (распространение, предоставление, доступ) персональных данных, прекратить обработку и уничтожить персональные данные в порядке и случаях, предусмотренных Законом о персональных данных;\n' +
        '\n' +
        '— исполнять иные обязанности, предусмотренные Законом о персональных данных.\n' +
        '\n' +
        '**4. Основные права и обязанности субъектов персональных данных**\n' +
        '4.1. Субъекты персональных данных имеют право:\n' +
        '\n' +
        '— получать информацию, касающуюся обработки его персональных данных, за исключением случаев, предусмотренных федеральными законами. Сведения предоставляются субъекту персональных данных Оператором в доступной форме, и в них не должны содержаться персональные данные, относящиеся к другим субъектам персональных данных, за исключением случаев, когда имеются законные основания для раскрытия таких персональных данных. Перечень информации и порядок ее получения установлен Законом о персональных данных;\n' +
        '\n' +
        '— требовать от оператора уточнения его персональных данных, их блокирования или уничтожения в случае, если персональные данные являются неполными, устаревшими, неточными, незаконно полученными или не являются необходимыми для заявленной цели обработки, а также принимать предусмотренные законом меры по защите своих прав;\n' +
        '\n' +
        '— выдвигать условие предварительного согласия при обработке персональных данных в целях продвижения на рынке товаров, работ и услуг;\n' +
        '\n' +
        '— на отзыв согласия на обработку персональных данных, а также, на направление требования о прекращении обработки персональных данных;\n' +
        '\n' +
        '— обжаловать в уполномоченный орган по защите прав субъектов персональных данных или в судебном порядке неправомерные действия или бездействие Оператора при обработке его персональных данных;\n' +
        '\n' +
        '— на осуществление иных прав, предусмотренных законодательством РФ.\n' +
        '\n' +
        '4.2. Субъекты персональных данных обязаны:\n' +
        '\n' +
        '— предоставлять Оператору достоверные данные о себе;\n' +
        '\n' +
        '— сообщать Оператору об уточнении (обновлении, изменении) своих персональных данных.\n' +
        '\n' +
        '4.3. Лица, передавшие Оператору недостоверные сведения о себе, либо сведения о другом субъекте персональных данных без согласия последнего, несут ответственность в соответствии с законодательством РФ.\n' +
        '\n' +
        '**5. Принципы обработки персональных данных**\n' +
        '5.1. Обработка персональных данных осуществляется на законной и справедливой основе.\n' +
        '\n' +
        '5.2. Обработка персональных данных ограничивается достижением конкретных, заранее определенных и законных целей. Не допускается обработка персональных данных, несовместимая с целями сбора персональных данных.\n' +
        '\n' +
        '5.3. Не допускается объединение баз данных, содержащих персональные данные, обработка которых осуществляется в целях, несовместимых между собой.\n' +
        '\n' +
        '5.4. Обработке подлежат только персональные данные, которые отвечают целям их обработки.\n' +
        '\n' +
        '5.5. Содержание и объем обрабатываемых персональных данных соответствуют заявленным целям обработки. Не допускается избыточность обрабатываемых персональных данных по отношению к заявленным целям их обработки.\n' +
        '\n' +
        '5.6. При обработке персональных данных обеспечивается точность персональных данных, их достаточность, а в необходимых случаях и актуальность по отношению к целям обработки персональных данных. Оператор принимает необходимые меры и/или обеспечивает их принятие по удалению или уточнению неполных или неточных данных.\n' +
        '\n' +
        '5.7. Хранение персональных данных осуществляется в форме, позволяющей определить субъекта персональных данных, не дольше, чем этого требуют цели обработки персональных данных, если срок хранения персональных данных не установлен федеральным законом, договором, стороной которого, выгодоприобретателем или поручителем по которому является субъект персональных данных. Обрабатываемые персональные данные уничтожаются либо обезличиваются по достижении целей обработки или в случае утраты необходимости в достижении этих целей, если иное не предусмотрено федеральным законом.\n' +
        '\n' +
        '**6. Цели обработки персональных данных**\n' +
        'Цель обработки: информирование Пользователя посредством отправки электронных писем\n' +
        '\n' +
        'Персональные данные: фамилия, имя, отчествоэлектронный адресномера телефонов\n' +
        '\n' +
        'Правовые основания: уставные (учредительные) документы Оператора, договоры, заключаемые между оператором и субъектом персональных данных\n' +
        '\n' +
        'Виды обработки персональных данных: Передача персональных данных\n' +
        '\n' +
        '**7. Условия обработки персональных данных**\n' +
        '7.1. Обработка персональных данных осуществляется с согласия субъекта персональных данных на обработку его персональных данных.\n' +
        '\n' +
        '7.2. Обработка персональных данных необходима для достижения целей, предусмотренных международным договором Российской Федерации или законом, для осуществления возложенных законодательством Российской Федерации на оператора функций, полномочий и обязанностей.\n' +
        '\n' +
        '7.3. Обработка персональных данных необходима для осуществления правосудия, исполнения судебного акта, акта другого органа или должностного лица, подлежащих исполнению в соответствии с законодательством Российской Федерации об исполнительном производстве.\n' +
        '\n' +
        '7.4. Обработка персональных данных необходима для исполнения договора, стороной которого либо выгодоприобретателем или поручителем по которому является субъект персональных данных, а также для заключения договора по инициативе субъекта персональных данных или договора, по которому субъект персональных данных будет являться выгодоприобретателем или поручителем.\n' +
        '\n' +
        '7.5. Обработка персональных данных необходима для осуществления прав и законных интересов оператора или третьих лиц либо для достижения общественно значимых целей при условии, что при этом не нарушаются права и свободы субъекта персональных данных.\n' +
        '\n' +
        '7.6. Осуществляется обработка персональных данных, доступ неограниченного круга лиц к которым предоставлен субъектом персональных данных либо по его просьбе (далее — общедоступные персональные данные).\n' +
        '\n' +
        '7.7. Осуществляется обработка персональных данных, подлежащих опубликованию или обязательному раскрытию в соответствии с федеральным законом.\n' +
        '\n' +
        '**8. Порядок сбора, хранения, передачи и других видов обработки персональных данных**\n' +
        'Безопасность персональных данных, которые обрабатываются Оператором, обеспечивается путем реализации правовых, организационных и технических мер, необходимых для выполнения в полном объеме требований действующего законодательства в области защиты персональных данных.\n' +
        '\n' +
        '8.1. Оператор обеспечивает сохранность персональных данных и принимает все возможные меры, исключающие доступ к персональным данным неуполномоченных лиц.\n' +
        '\n' +
        '8.2. Персональные данные Пользователя никогда, ни при каких условиях не будут переданы третьим лицам, за исключением случаев, связанных с исполнением действующего законодательства либо в случае, если субъектом персональных данных дано согласие Оператору на передачу данных третьему лицу для исполнения обязательств по гражданско-правовому договору.\n' +
        '\n' +
        '8.3. В случае выявления неточностей в персональных данных, Пользователь может актуализировать их самостоятельно, путем направления Оператору уведомление на адрес электронной почты Оператора housesip@yandex.ru с пометкой «Актуализация персональных данных».\n' +
        '\n' +
        '8.4. Срок обработки персональных данных определяется достижением целей, для которых были собраны персональные данные, если иной срок не предусмотрен договором или действующим законодательством.\n' +
        'Пользователь может в любой момент отозвать свое согласие на обработку персональных данных, направив Оператору уведомление посредством электронной почты на электронный адрес Оператора housesip@yandex.ru с пометкой «Отзыв согласия на обработку персональных данных».\n' +
        '\n' +
        '8.5. Вся информация, которая собирается сторонними сервисами, в том числе платежными системами, средствами связи и другими поставщиками услуг, хранится и обрабатывается указанными лицами (Операторами) в соответствии с их Пользовательским соглашением и Политикой конфиденциальности. Субъект персональных данных и/или с указанными документами. Оператор не несет ответственность за действия третьих лиц, в том числе указанных в настоящем пункте поставщиков услуг.\n' +
        '\n' +
        '8.6. Установленные субъектом персональных данных запреты на передачу (кроме предоставления доступа), а также на обработку или условия обработки (кроме получения доступа) персональных данных, разрешенных для распространения, не действуют в случаях обработки персональных данных в государственных, общественных и иных публичных интересах, определенных законодательством РФ.\n' +
        '\n' +
        '8.7. Оператор при обработке персональных данных обеспечивает конфиденциальность персональных данных.\n' +
        '\n' +
        '8.8. Оператор осуществляет хранение персональных данных в форме, позволяющей определить субъекта персональных данных, не дольше, чем этого требуют цели обработки персональных данных, если срок хранения персональных данных не установлен федеральным законом, договором, стороной которого, выгодоприобретателем или поручителем по которому является субъект персональных данных.\n' +
        '\n' +
        '8.9. Условием прекращения обработки персональных данных может являться достижение целей обработки персональных данных, истечение срока действия согласия субъекта персональных данных, отзыв согласия субъектом персональных данных или требование о прекращении обработки персональных данных, а также выявление неправомерной обработки персональных данных.\n' +
        '\n' +
        '**9. Перечень действий, производимых Оператором с полученными персональными данными**\n' +
        '9.1. Оператор осуществляет сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление и уничтожение персональных данных.\n' +
        '\n' +
        '9.2. Оператор осуществляет автоматизированную обработку персональных данных с получением и/или передачей полученной информации по информационно-телекоммуникационным сетям или без таковой.\n' +
        '\n' +
        '**10. Трансграничная передача персональных данных**\n' +
        '10.1. Оператор до начала осуществления деятельности по трансграничной передаче персональных данных обязан уведомить уполномоченный орган по защите прав субъектов персональных данных о своем намерении осуществлять трансграничную передачу персональных данных (такое уведомление направляется отдельно от уведомления о намерении осуществлять обработку персональных данных).\n' +
        '\n' +
        '10.2. Оператор до подачи вышеуказанного уведомления, обязан получить от органов власти иностранного государства, иностранных физических лиц, иностранных юридических лиц, которым планируется трансграничная передача персональных данных, соответствующие сведения.\n' +
        '\n' +
        '**11. Конфиденциальность персональных данных**\n' +
        'Оператор и иные лица, получившие доступ к персональным данным, обязаны не раскрывать третьим лицам и не распространять персональные данные без согласия субъекта персональных данных, если иное не предусмотрено федеральным законом.\n' +
        '\n' +
        '**12. Заключительные положения**\n' +
        '12.1. Пользователь может получить любые разъяснения по интересующим вопросам, касающимся обработки его персональных данных, обратившись к Оператору с помощью электронной почты housesip@yandex.ru.\n' +
        '\n' +
        '12.2. В данном документе будут отражены любые изменения политики обработки персональных данных Оператором. Политика действует бессрочно до замены ее новой версией.\n' +
        '\n' +
        '12.3. Актуальная версия Политики в свободном доступе расположена в сети Интернет по адресу https://housesip.ru/policy.'
}
export const guaranteesText = 'Мы в SIP Houses гордимся высочайшим качеством наших продуктов и услуг, и поэтому с полной уверенностью предоставляем гарантию на СИП-панели сроком на 5 лет. Это подтверждает нашу веру в надежность и долговечность материалов, из которых строятся ваши дома. А также, когда вы решаете строить дом «под ключ» с нами, мы обеспечиваем 5-летнюю гарантию на выполненные строительные работы. Вы можете быть уверены, что ваш дом, построенный нашими специалистами, будет служить вам верой и правдой.'