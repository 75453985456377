import React from 'react';
import {useEffect, useState} from "react";

import {Card, Grid2} from '@mui/material';
import {useFetching} from "../hooks/useFetching";
import PortfolioService from "../api/PortfolioService";
import ModalImage from "../components/UI/ModalImage";
import Layout from "../components/layout/Layout";
import HeroContent from "../components/layout/HeroContent";
import DefaultBox from "../components/UI/DefaultBox";
import PortfolioImage from "../components/UI/PortfolioImage";
import Error from "../components/Error/Error";
import CustomPagination from "../components/UI/CustomPagination";
import fetchCallback from "../utils/FetchCallback";
import {usePagination} from "../hooks/usePagination";
import {Helmet} from "react-helmet";

function PortfolioPage() {
    const {page, handlePageChange} = usePagination()
    const [totalPages, setTotalPages] = useState(5);
    const limit = 18

    const [images, setImages] = useState([]);

    const [fetchImages, isImagesLoading, imagesErrors] = useFetching(
        fetchCallback({
            page,
            limit,
            service:
            PortfolioService,
            setEntities: setImages,
            setTotalPages
        })
    )
    const [modalImage, setModalImage] = useState('');
    const [openModal, setOpenModal] = useState(false);
    const handlePhotoClick = (url) => {
        setModalImage(url);
        setOpenModal(true);
    }

    useEffect(() => {
        fetchImages();
    }, [])

    const heroContent =
        <HeroContent
            header={"Портфолио"}
        />;
    return (
        <Layout heroContent={heroContent}>
            <Helmet><title>Портфолио</title></Helmet>

            {imagesErrors && <Error error={imagesErrors} />}
            {!imagesErrors && (
                <DefaultBox sx={{mb: 2}}>
                    <Grid2 container spacing={2}>
                        {(isImagesLoading ? Array.from(new Array(6)) : images).map((item, index) => (
                            <Grid2 item size={{md: 2, xs: 12, sm: 12, lg: 2}} key={index}>
                                <Card sx={{ height: '100%' }}>

                                    <PortfolioImage item={item} handleClick={handlePhotoClick} />
                                </Card>
                            </Grid2>
                        ))}
                        <ModalImage
                            open={openModal}
                            onClose={()=> setOpenModal(false)}
                            imageUrl={modalImage}
                        />
                    </Grid2>
                    <DefaultBox sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
                        <CustomPagination
                            totalPages={totalPages}
                            page={isImagesLoading ? page : page}
                            handleChange={handlePageChange}
                        />
                    </DefaultBox>
                </DefaultBox>
            )}
        </Layout>
    );
}

export default PortfolioPage;
