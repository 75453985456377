import React, {useEffect, useState} from 'react';
import HouseService from '../api/HouseService';
import {useFetching} from "../hooks/useFetching";
import ServiceBox from "../components/ServiceBox";
import ContactBox from "../components/ContactBox";
import CategoryBox from "../components/CategoryBox";
import HouseOnHomePage from "../components/ShowerHouses";
import Layout from "../components/layout/Layout";
import HeroContent from "../components/layout/HeroContent";
import ModalForm from "../components/UI/ModalForm";
import CalcForm from "../components/Form/CalcForm";
import ClickButton from "../components/UI/Button/ClickButton";
import DefaultBox from "../components/UI/DefaultBox";
import DroppedBox from "../components/DroppedBox";
import {Typography} from "@mui/material";
import InfoTable from "../components/InfoTable";
import CompanyInfo from "../components/CompanyInfo";
import ImageForm from "../components/Form/ImageForm";
import SimpleForm from "../components/Form/SimpleForm";
import {Helmet} from "react-helmet";

function HomePage() {
    const [showComplectation, setShowComplectation] = useState(false);
    const [showPriceTable, setShowPriceTable] = useState(false);
    const [oneFloorHouses, setOneFloorHouses] = useState([]);
    const [twoFloorHouses, setTwoFloorHouses] = useState([]);
    const [fetchOneFloorHouses, isOneFloorHousesLoading, oneFloorHousesErrors] = useFetching(
        async () => {
            const query = {
                floors: 1,
                limit: 6
            }
            const response = await HouseService.getAll(query);

            setOneFloorHouses(response.data);
        }
    )
    const [fetchTwoFloorHouses, isTwoFloorHousesLoading, twoFloorHousesErrors] = useFetching(
        async () => {
            const query = {
                floors: 2,
                limit: 6
            }
            const response = await HouseService.getAll(query);
            setTwoFloorHouses(response.data);
        }
    )
    const handleShowComplectation = () => setShowComplectation((prev) => !prev);
    const handleShowPrice = () => setShowPriceTable((prev) => !prev);

    const [openCalc, setOpenCalc] = useState(false);
    const handleOpenCalc = () => setOpenCalc(true);
    const handleCloseCalc = () => setOpenCalc(false);

    const heroContent =
        <HeroContent
            header={'Строительство домов из СИП-панелей'}
            subheader={"Производство и продажа СИП-панелей \n и готовых домокомплектов"}
            img={true}
            element={
                <DefaultBox sx={{py: 0, pt: 5}}>
                    <ClickButton handleOpen={handleOpenCalc} title={"Расчитать стоимость"} sx={{px: 2}}/>
                    <ModalForm open={openCalc} onClose={handleCloseCalc}>
                        <CalcForm />
                    </ModalForm>
                </DefaultBox>
        }
        />;

    useEffect(() => {
        fetchOneFloorHouses()
        fetchTwoFloorHouses()
    }, [])
    return (
        <Layout heroContent={heroContent} heroImage={"/images/main.jpg"}>
            <Helmet><title>SIP Houses</title></Helmet>
            <DefaultBox>
                <CompanyInfo />
                <CategoryBox/>

                <HouseOnHomePage
                    list={oneFloorHouses}
                    isLoading={isOneFloorHousesLoading}
                    errors={oneFloorHousesErrors}
                    floors={1}
                />

                <HouseOnHomePage
                    list={twoFloorHouses}
                    isLoading={isTwoFloorHousesLoading}
                    errors={twoFloorHousesErrors}
                    floors={2}
                />


                <ImageForm/>
                <DroppedBox onShow={handleShowComplectation} show={showComplectation} title={"Комплектация"}>
                    <Typography variant="body1">
                        Этот продукт представляет собой набор строительных материалов, известных как сип-панели, которые используются для возведения домов или сооружений общественного и промышленного назначения в соответствии с проектной документацией.
                    </Typography>
                    <Typography variant="body1">
                        Комплект сип-панелей обладает четкой структурой и маркировкой деталей. Такая технология пользуется высоким спросом благодаря быстроте монтажа, энергоэффективности построек и доступной стоимости.
                    </Typography>

                </DroppedBox>
                <DroppedBox onShow={handleShowPrice} show={showPriceTable} title={"Цены"}>
                    <InfoTable/>
                </DroppedBox>
                <ServiceBox/>
                <SimpleForm/>
                <ContactBox/>

            </DefaultBox>
        </Layout>

    );
}

export default HomePage;
