import {Box} from "@mui/material";

const LabelBox = ({children}) => {
    return (
        <Box
            sx={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                backgroundColor: 'rgba(255, 255, 255, 0.6)',
                color: '#fff',
                padding: '4px 8px',
            }}
        >
            {children}
        </Box>
    )
}
export default LabelBox