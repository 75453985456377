import api from "./api";

class HouseService {
    static async getAll(query={}) {
        const params = new URLSearchParams();

        if (query.floors) {
            params.append('floors', query.floors);
        }
        if (query.garage) {
            params.append('garage', true);
        }
        if (query.terrace) {
            params.append('terrace', true);
        }
        if (query.areaCode) {
            params.append('area_range', query.areaCode);
        }
        if (query.page) {
            params.append('page', query.page);
        }
        if (query.limit) {
            params.append('limit', query.limit);
        }
        const response = await api.get(`/api/houses/?${params.toString()}`);
        return response.data;
    }
    static async getById(id) {
        const response = await api.get(`/api/houses/${id}`);
        return response.data;
    }
    static async getRelated(entity, limit){
        const params = new URLSearchParams();
        if(limit){
            params.append('limit', limit);
        }

        const response = await api.get(`/api/houses/related/${entity.id}`, {params: params});
        return response.data;
    }
    static async create(formData, token){
        const response = await api.post(
            `/api/houses/`,
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${token}`
                },
            });
        return response.data
    }
    static async deleteById(id, token) {
        const response = await api.delete(`/api/houses/${id}`,
            { headers: {
                    Authorization: `Bearer ${token}`
                }}
            );
        return response.data;
    }

    static async update(id, formData, token){
        const response = await api.patch(
            `/api/houses/${id}`,
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${token}`
                },
            });
        return response.data
    }
}


export default HouseService;