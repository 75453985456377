import {Typography} from "@mui/material";
import React from "react";
import Layout from "../components/layout/Layout";
import HeroContent from "../components/layout/HeroContent";
import DefaultBox from "../components/UI/DefaultBox";
import {guaranteesText} from "../consts/texts";
import {Helmet} from "react-helmet";

const GuaranteesPage = () => {
    const heroContent =
        <HeroContent
            header={"Гарантии"}
        />;
    return (
        <Layout heroContent={heroContent}>
            <Helmet><title>Гарантии</title></Helmet>
            <DefaultBox>
                <Typography variant="desc">
                    {guaranteesText}
                </Typography>
            </DefaultBox>
        </Layout>

    )
}

export default GuaranteesPage;