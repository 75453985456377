import DefaultBox from "./UI/DefaultBox";
import DroppedButton from "./UI/Button/DroppedButton";
const DroppedBox = ({onShow, show, title, children}) => {
    return (
        <DefaultBox sx={{pt: 2, pb: 2}}>
            <DroppedButton onShow={onShow} show={show} title={title}/>
            {show && (
                <DefaultBox>
                    {children}
                </DefaultBox>
            )}
        </DefaultBox>

    )
}

export default DroppedBox;