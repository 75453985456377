import {useFetching} from "../hooks/useFetching";
import HouseService from "../api/HouseService";
import React, {useEffect, useState} from "react";
import HouseCard from '../components/HouseCard';
import HouseFilters from "../components/Filters/HouseFilters";
import {useSearchParams} from "react-router-dom";
import HeroContent from "../components/layout/HeroContent";
import ListPage from "./ListPage";
import fetchFiltersCallback from "../utils/FetchFiltersCallback";
import {usePagination} from "../hooks/usePagination";

const ListHousePage = () => {
    const {page, handlePageChange, setPage} = usePagination()
    const [totalPages, setTotalPages] = useState(1);
    const limit = 9

    const [searchParams, setSearchParams] = useSearchParams();
    const floorsParam = searchParams.get('floors');
    const garageParam = searchParams.get('garage');
    const terraceParam = searchParams.get('terrace');
    const areaCodeParam = searchParams.get('areaCode');

    const filters = {
        floors: floorsParam ? Number(floorsParam) : null,
        garage: garageParam === 'true',
        terrace: terraceParam === 'true',
        areaCode: areaCodeParam ? Number(areaCodeParam) : null,
    };

    const [houses, setHouses] = useState([]);

    const [fetchHouses, isHousesLoading, housesErrors] = useFetching(
        fetchFiltersCallback({
            filters,
            page,
            limit,
            service: HouseService,
            setEntities: setHouses,
            setTotalPages
        })
    )
    useEffect(() => {
        fetchHouses();
    }, [page,filters.areaCode, filters.garage, filters.terrace, filters.floors]);
    const heroContent =
        <HeroContent
            img={true}
            header={"Проекты домов из СИП-Панелей"}
            description={"Выберите идеальный дом из наших готовых проектов или заказывайте уникальный – реализуем индивидуальный дизайн вашей мечты! С СИП-панелями «SIP Houses» комфорт и экономия идут рука об руку. Создайте свой неповторимый дом с нами!"}
        />;
    return (
        <ListPage
            helmet={"Дома"}
            heroImage={"/images/houses.jpg"}
            heroContent={heroContent}
            FiltersComponent={HouseFilters}
            isLoading={isHousesLoading}
            items={houses}
            errors={housesErrors}
            CardComponent={HouseCard}
            totalPages={totalPages}
            page={page}
            handlePageChange={handlePageChange}
            searchParams={searchParams}
            setSearchParams={setSearchParams}
            setPage={setPage}
        />
    )
}

export default ListHousePage;