
import React from 'react';
import {Box, Card, CardContent} from '@mui/material';
import ParamsBox from "./ParamsBox";
import Price from "./Price";
import api from "../../api/api";
import CardButton from "../UI/Button/CardButton";
import {Link} from "react-router-dom";
import ImageCard from "../UI/ImageCard";


function BaseCard({
                      isLoading,
                      imageUrl,
                      price,
                      title,
                      link,
                      parameters=[]}) {
    return (
        <Card
            sx={{
                height: '100%',
                display: 'flex',
                flexDirection:'column',
                position: 'relative',
            }}
        >
                <Box sx={{ position: 'relative', cursor: 'pointer'}} component={Link} to={link}>
                    <ImageCard
                        path={`${api.defaults.baseURL}/${imageUrl}`}
                        title={title}
                        isLoading={isLoading}
                    />
                </Box>
            <CardContent
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    flexGrow: 1,
                }}
            >
                <ParamsBox
                    parameters={parameters}
                    isLoading={isLoading}
                />

                <Price
                    price={price}
                    isLoading={isLoading}
                />

                <CardButton
                    link={link}
                    isLoading={isLoading}
                />

            </CardContent>
        </Card>
    );
}

export default BaseCard;
