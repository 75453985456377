import api from "./api";

class TownhouseService {
    static async getAll(query={}) {
        const params = new URLSearchParams();
        if (query.apartments) {
            params.append('apartments', query.apartments);
        }
        if (query.page) {
            params.append('page', query.page);
        }
        if (query.limit) {
            params.append('limit', query.limit);
        }
        const response = await api.get(`/api/townhouses/?${params.toString()}`);
        return response.data;
    }
    static async getById(id) {
        const response = await api.get(`/api/townhouses/${id}`);
        return response.data;
    }

    static async create(formData, token){
        const response = await api.post(
            `/api/townhouses/`,
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${token}`
                },
            });
        return response.data
    }
    static async deleteById(id, token) {
        const response = await api.delete(`/api/townhouses/${id}`,
            {headers: {
                Authorization: `Bearer ${token}`
                }}
            );
        return response.data;
    }
    static async update(id, formData, token){
        const response = await api.patch(
            `/api/townhouses/${id}`,
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${token}`
                },
            });
        return response.data
    }
}


export default TownhouseService;