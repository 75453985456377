import React, {useEffect, useState} from 'react';
import { Button, Switch, FormControlLabel, Box, Typography } from '@mui/material';
import InputField from "../components/UI/Input/InputField";
function BaseForm({ required, fields, service, onSuccess, onError, entity, token }) {
    const [formState, setFormState] = useState({});
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleChange = (fieldName, value) => {
        setFormState((prev) => ({ ...prev, [fieldName]: value }));
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);

        try {
            const fd = new FormData();

            for (const field of fields) {
                const fieldValue = formState[field.name];
                if (field.type === 'file') {
                    if (field.multiple && fieldValue) {
                        for (const file of fieldValue) {
                            if (file instanceof File) fd.append(field.name, file);
                        }
                    } else if (fieldValue) {
                        if (fieldValue instanceof File) fd.append(field.name, fieldValue);
                    }
                } else if (field.type === 'switch') {
                    fd.append(field.name, fieldValue ? 'true' : 'false');
                } else {
                    fd.append(field.name, fieldValue || '');
                }
            }
            let savedEntity;
            if(!entity){
                savedEntity = await service.create(fd, token)
            } else {
                savedEntity = await service.update(entity.id, fd, token)
            }
            onSuccess && onSuccess(savedEntity);
        } catch (err) {
            const msg = err.response?.data?.detail || err.message;
            setError(msg);
            if (onError) onError(err);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        if(entity){
            setFormState(entity);
        } else {
            setFormState({});
        }
    }, [entity]);
    return (
        <Box component="form" onSubmit={handleSubmit}>
            {fields.map((field) => {
                if (field.type === 'file') {
                    return (
                        <Box key={field.name} sx={{ mb: 2 }}>
                            <Typography>{field.label}</Typography>
                            <input
                                type="file"
                                multiple={field.multiple}
                                required={required}
                                onChange={(e) => {
                                    if (field.multiple) {
                                        handleChange(field.name, e.target.files);
                                    } else {
                                        handleChange(field.name, e.target.files[0]);
                                    }
                                }}
                            />
                        </Box>
                    );
                } else if (field.type === 'switch') {
                    return (
                        <FormControlLabel
                            key={field.name}
                            control={
                                <Switch
                                    checked={!!formState[field.name]}
                                    onChange={(e) => handleChange(field.name, e.target.checked)}
                                />
                            }
                            label={field.label}
                        />
                    );
                } else {
                    return (
                        <InputField
                            key={field.name}
                            label={field.label}
                            type={field.type}
                            required={field.required}
                            value={formState[field.name] || ''}
                            onChange={(e) => handleChange(field.name, e.target.value)}
                        />
                    );
                }
            })}

            {error && <Typography color="error">Ошибка: {error}</Typography>}
            <Button type="submit" variant="contained" disabled={loading}>
                {entity  ? 'Сохранить' : 'Создать'}
            </Button>
        </Box>
    );
}

export default BaseForm;
