import React, {useEffect, useState} from "react";
import {useFetching} from "../../hooks/useFetching";
import DefaultBox from "../../components/UI/DefaultBox";
import InfoTable from "../InfoTable";
import {Button, Typography} from "@mui/material";
import ModalForm from "../../components/UI/ModalForm";
import BaseForm from "../BaseForm";
import CustomPagination from "../../components/UI/CustomPagination";
import {usePagination} from "../../hooks/usePagination";

const BasePage = ({
    header,
    service,
    fields,
    requiredToken=false
                  }) => {
    const {page, handlePageChange} = usePagination()
    const token = localStorage.getItem("token");
    const [requiredFields, setRequiredFields] = useState(true);
    const [openModal, setOpenModal] = useState(false);
    const handleOpenModal = (entity=null) => {
        setRequiredFields(entity===null)
        setCurrentEntity(entity)
        setOpenModal(true);
    }
    const handleCloseModal = () => setOpenModal(false);
    const [entities, setEntities] = useState([]);
    const [totalPages, setTotalPages] = useState(1);
    const limit = 2
    const [fetchEntities, isEntitiesLoading, entitiesErrors] = useFetching(
        async () => {
            const query = {}
            query.page = page
            query.limit = limit
            let response;
            if (requiredToken) {
                response = await service.getAll(token, query);
            } else {
                response = await service.getAll(query);
            }

            setEntities(response.data);
            const totalItems = response.totalItems
            setTotalPages(Math.ceil(totalItems / limit));
        }
    )
    const [currentEntity, setCurrentEntity] = useState(null);
    const handleOnDelete = async (id) => {
        try {
            await service.deleteById(id, token);
            setEntities(prevEntities => prevEntities.filter(entity => entity.id !== id));
        } catch (error) {
            alert(error.message);
        }
    }
    const handleFormSuccess = (savedEntity) => {
        if (!currentEntity) {
            setEntities((prev) => [...prev, savedEntity]);
        } else {
            setEntities((prev) =>
                prev.map((item) => (item.id === savedEntity.id ? savedEntity : item))
            );
        }
        handleCloseModal();
    };
    useEffect(() => {
        fetchEntities();
    }, [page]);
    return (
        <DefaultBox>
                <DefaultBox sx={{width:'90%'}}>
                    <DefaultBox sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <Typography variant="h5">{header}</Typography>
                        <Button onClick={()=>handleOpenModal(null)}>Новая запись</Button>
                    </DefaultBox>
                    <ModalForm open={openModal} onClose={handleCloseModal}>
                        <BaseForm
                            required={requiredFields}
                            token={token}
                            fields={fields}
                            service={service}
                            entity={currentEntity}
                            onSuccess={handleFormSuccess}
                        />
                    </ModalForm>

                </DefaultBox>

            <DefaultBox>

                    <InfoTable
                        data={entities}
                        fields={fields}
                        isLoading={isEntitiesLoading}
                        onEdit={handleOpenModal}
                        onDelete={handleOnDelete}
                    />

            </DefaultBox>
            <CustomPagination page={page} totalPages={totalPages} handleChange={handlePageChange} />
        </DefaultBox>

    )
}
export default BasePage;