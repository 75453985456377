import { Typography} from "@mui/material";
import React from "react";
import Layout from "../components/layout/Layout";
import HeroContent from "../components/layout/HeroContent";
import DefaultBox from "../components/UI/DefaultBox";
import {paymentsText} from "../consts/texts";
import {Helmet} from "react-helmet";

const PayMethodsPage = () => {
    const heroContent =
        <HeroContent
            header={"Способы оплаты"}
        />;
    return (
        <Layout heroContent={heroContent}>
            <Helmet><title>Способы оплаты</title></Helmet>

            <DefaultBox>
                <Typography variant="desc" paragraph>
                    {paymentsText}
                </Typography>
            </DefaultBox>
        </Layout>
    )
}

export default PayMethodsPage;