const fetchCallback = ({
                           page,
                           limit,
                           service,
                           setEntities,
                           setTotalPages,
}) => {
    return async () => {
        const query = { page, limit };
        const  response = await service.getAll(query);
        setEntities(response.data);
        const totalItems = response.totalItems;
        setTotalPages(Math.ceil(totalItems / limit));

    };
}

export default fetchCallback;

