import {Box} from "@mui/material";

const DefaultBox = ({children, sx={}}) => {
    const defaultStyles = { width: '100%',  margin: '0 auto', py: 4  }
    return (
        <Box sx={{...defaultStyles, ...sx}}>
            {children}
        </Box>
    )
}

export default DefaultBox;