import Layout from "../components/layout/Layout";
import HeroContent from "../components/layout/HeroContent";
import ContactBox from "../components/ContactBox";
import {Helmet} from "react-helmet";
import React from "react";

const ContactPage = () => {
    const heroContent =
        <HeroContent
            header={"Контакты"}
        />;
    return (
        <Layout heroContent={heroContent}>
            <Helmet><title>Контакты</title></Helmet>
            <ContactBox/>
        </Layout>

    )
}

export default ContactPage