import React from 'react';
import {
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Typography
} from '@mui/material';

function PanelTable({ data }) {
    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell >
                        <Typography variant="subtitle1" fontWeight="bold">
                            Панель
                        </Typography>
                    </TableCell>
                    <TableCell>
                        <Typography variant="subtitle1" fontWeight="bold">
                            Цена
                        </Typography>
                    </TableCell>
                </TableRow>
            </TableHead>

            <TableBody>
                {data.map((item, idx) => (
                    <React.Fragment key={idx}>
                        <TableRow sx={{backgroundColor: (theme) => theme.palette.primary.second}}>
                            <TableCell>
                                Панели {item.length} x {item.width} x 100-200. Плотность {item.density} кг
                            </TableCell>
                            <TableCell />
                        </TableRow>

                        {item.panels.map((panel) => (
                            <TableRow key={panel.id}>
                                <TableCell>
                                    Панель {item.length} x {item.width} x {panel.height}
                                    , {panel.name}, {item.density} кг/м³
                                </TableCell>
                                <TableCell>{panel.price}</TableCell>
                            </TableRow>
                        ))}
                    </React.Fragment>
                ))}
            </TableBody>
        </Table>
    );
}

export default PanelTable;
