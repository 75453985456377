import DefaultBox from "../UI/DefaultBox";
import {Typography} from "@mui/material";

const Error = ({error}) => {
    return (
        <DefaultBox>
            <Typography variant="h2">{error}</Typography>
        </DefaultBox>
    )
}

export default Error