const fetchFiltersCallback =  ({
                                        filters = {},
                                        page,
                                        limit,
                                        service,
                                        setEntities,
                                        setTotalPages
                                    }) =>{
    return async () => {
        const query = {};
        query.page = page;
        query.limit = limit;
        if (filters.floors) query.floors = filters.floors;
        if (filters.garage) query.garage = true;
        if (filters.terrace) query.terrace = true;
        if (filters.areaCode) query.areaCode = filters.areaCode;
        if (filters.apartments) query.apartments = filters.apartments;
        const response = await service.getAll(query);
        setEntities(response.data);
        const totalItems = response.totalItems
        setTotalPages(Math.ceil(totalItems / limit));
    };
}

export default fetchFiltersCallback;
