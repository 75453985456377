import {Button} from "@mui/material";
import React from "react";

const SubmitButton = ({
                          disabled,
                          error,
                          submitted,
                          sx={}}) => {
    const defaultStyles = {
        mt: 2,
        px: 0,
        textTransform: 'none',
        typography: "h6",
        color: "white",
    }
    const buttonText = error ? "Ошибка" : submitted ? "✓" : "Отправить заявку";
    const buttonColor = error
        ? "red"
        : submitted
            ? "green"
            : "primary.btns";
    const buttonHoverColor = error
        ? "red"
        : submitted
            ? "green"
            : "primary.btns";
    return (
        <Button
            type="submit"
            variant="contained"
            fullWidth
            disabled={disabled}
            sx={{
                ...defaultStyles,
                ...sx,
                backgroundColor: buttonColor,
                "&:hover": {
                    backgroundColor: buttonHoverColor,
                },
            }}
        >
            {buttonText}
        </Button>
    )
}

export default SubmitButton