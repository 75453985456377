import React from "react";
import InfoGrid from "./InfoGrid";
import {infoParams} from "../consts/consts";
const CompanyInfo = () => {
    return (
        <InfoGrid
            data={infoParams}
            type={"company"}
        />
    )
}

export default CompanyInfo;