import {Box, Grid2, Typography} from "@mui/material";
import React from "react";
import CompanyInfo from "../components/CompanyInfo";
import Layout from "../components/layout/Layout";
import HeroContent from "../components/layout/HeroContent";
import DefaultBox from "../components/UI/DefaultBox";
import {aboutText} from "../consts/texts";
import { Helmet } from 'react-helmet';
const AboutPage = ()=> {
    const heroContent =
        <HeroContent
            header={"О компании"}
        />;
    return (
        <Layout heroContent={heroContent}>
                <Helmet><title>О нас</title></Helmet>
                <DefaultBox >
                    <CompanyInfo />
                    {aboutText.map((block, idx)=> (
                        <DefaultBox>
                        <Grid2 container spacing={4}>
                            <Grid2 item size={{xs: 12, sm: 12, md: 6}}>
                                <Typography variant="ch3" gutterBottom>{block.title}</Typography>
                                <Typography variant="desc">
                                    {block.text}
                                </Typography>
                            </Grid2>
                            <Grid2 item size={{xs: 12, sm: 12, md: 6}}>
                                    <Box sx={{ mb: 2 }}>
                                        <img
                                            src={block.photo}
                                            alt="main"
                                            style={{ width: '100%', cursor: 'pointer' }}
                                        />
                                    </Box>
                            </Grid2>
                        </Grid2>
                        </DefaultBox>
                    ) )}

                </DefaultBox>
        </Layout>

    )
}

export default AboutPage;