import {Button} from "@mui/material";
import React from "react";

const ImageButton = ({...props}) => {
    return (
        <Button
            variant="outlined"
            component="label"
            sx={(theme)=> ({
            px: 2,
            textTransform: 'none',
                backgroundColor: "black",
            typography: "ch6",
                borderColor: theme.palette.text.secondary,
        })}

        >
            Прикрепить изображение
            <input
                type="file"
                hidden
                accept=".jpg"
                {...props}
            />
        </Button>
    )
}
export default ImageButton