import DefaultBox from "../UI/DefaultBox";
import CategoryButton from "../UI/Button/CategoryButton";
const BaseFilter = ({list, isActive, handleFilter, func}) => {
    return (
        <DefaultBox sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: 1,
            flexDirection: { xs: "column", sm: "column", md: "row" }
        }}>
            {list.map((value, index) => (
                <CategoryButton
                    isActive={isActive(func(index))}
                    onClick={() => handleFilter(func(index))}
                    sx={{ mr: 1 }}
                    key={index}
                    title={!value ? func(index): value }
                />

            ))}

        </DefaultBox>
    )
}

export default BaseFilter