import {Grid2} from "@mui/material";
import DefaultBox from "../components/UI/DefaultBox";
import CustomPagination from "../components/UI/CustomPagination";
import Layout from "../components/layout/Layout";
import React from "react";
import Error from "../components/Error/Error";
import {Helmet} from "react-helmet";

const ListPage = ({
                        helmet,
                      heroImage,
                      heroContent,
                      FiltersComponent,
                      isLoading,
                      items,
                      errors,
                      CardComponent,
                      totalPages,
                      page,
                      handlePageChange,
                      searchParams,
                      setSearchParams,
                      setPage
}) => {
    return (
        <Layout heroImage={heroImage} heroContent={heroContent}>
            <Helmet><title>{helmet}</title></Helmet>

            <FiltersComponent
                searchParams={searchParams}
                setSearchParams={setSearchParams}
                setPage={setPage}
            />

            {errors && <Error error={errors} />}
            {!errors &&
                <Grid2 container spacing={2} alignItems="stretch">
                    {(isLoading ? Array.from(new Array(3)) : items).map((item, index) => (
                        <Grid2 item size={{xs: 12, sm: 12, md: 4, lg: 4}} sx={{display: "flex"}}  key={index}>
                            <CardComponent item={item}/>
                        </Grid2>
                    ))}
                </Grid2>
            }

            <DefaultBox sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
                <CustomPagination
                    totalPages={totalPages}
                    page={isLoading ? page : page}
                    handleChange={handlePageChange}
                />
            </DefaultBox>

        </Layout>
    )
}

export default ListPage