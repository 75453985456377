import { Typography} from "@mui/material";
import React from "react";
import DefaultBox from "../UI/DefaultBox";

const HeroContent = ({
                         header,
                         subheader,
                         description,
                         element,
                         price,
                         img =  false
}) => {
    return (
        <DefaultBox sx={{
            mt: 2, py: 0,
        }}>
            <DefaultBox sx={{py: 1, px: 1, backgroundColor: img? "rgba(255,255,255,0.85)" : "inherit",boxSizing: "border-box", borderRadius: "10px"}}>
                {header &&
                    <Typography variant={"ch3"} color={"forText"}>
                        {header}
                    </Typography>
                }
                {subheader &&
                    <Typography variant={"h4"}>
                        {subheader}
                    </Typography>
                }
                {description &&
                    <Typography variant={"h6"}>
                        {description}
                    </Typography>
                }
                {price &&
                    <Typography variant="ch3">
                        Цена от{" "}
                        <Typography component="span" variant="h3">
                            {price}
                        </Typography>{" "}
                        руб.
                    </Typography>
                }
            </DefaultBox>

            {element}

        </DefaultBox>
    )
}

export default HeroContent;