export const infoParams = [
    { label: '2010', value: "год основания", size: {xs: 8, sm: 8, md: 8, lg: 8}},
    { label: '100%', value: "собственное производство", size: {xs: 4, sm: 4, md:4, lg: 4} },
    { label: '100', value: "лет гарантии", size: {xs: 4, sm: 4, md:4, lg: 4} },
    { label: '50', value:"готовых проектов" , size: {xs: 4, sm: 4, md:4, lg: 4}},
    { label: '60', value: "дней постройки дома", size: {xs: 4, sm: 4, md:4, lg: 4}},
]

export const infoTableValues = [
    ['Цена', '12000 руб/м2', '20000 руб/м2', '32000 руб/м2'],
    ['Проект', 'Да', 'Да', 'Да'],
    ['Стеновые СИП-панели', 'Да', 'Да', 'Да'],
    ['Пиломатериалы, крепеж', 'Да', 'Да', 'Да'],
    ['Доставка материалов', 'Нет', 'Да', 'Да'],
    ['Комплектация и монтаж дома', 'Нет', 'Да', 'Да'],
    ['Фундамент', 'Нет', 'Нет', 'Да'],
    ['Кровельные материалы', 'Нет', 'Нет', 'Да'],
    ['Система вентиляции', 'Нет', 'Нет', 'Да'],
    ['Окна', 'Нет', 'Нет', 'Да'],
    ['Входные двери', 'Нет', 'Нет', 'Да'],
]