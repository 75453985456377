import api from "./api";

class LoginService {
    static async login(query) {
        const params = new URLSearchParams();
        params.append("username", query.login);
        params.append("password", query.password);
        const response = await api.post('/api/admin/token', params.toString(), {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        });
        return response;
    }


}
export default LoginService;