import * as Yup from "yup";
import { baseFieldsSchema } from "./baseFields";

const MAX_FILE_SIZE = 2 * 1024 * 1024;

export const schemaWithFile = Yup.object().shape({
    ...baseFieldsSchema,
    file: Yup.mixed()
        .required("Прикрепите файл")
        .test("fileSize", "Файл слишком большой (макс 2 МБ)", (value) => {
            if (!value || !value[0]) return false;
            return value[0].size <= MAX_FILE_SIZE;
        })
        .test("fileType", "Только JPG формат", (value) => {
            if (!value || !value[0]) return false;
            return /image\/jpeg/.test(value[0].type);
        }),
});