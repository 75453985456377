import {Typography} from "@mui/material";
import React from "react";
import Layout from "../components/layout/Layout";
import HeroContent from "../components/layout/HeroContent";
import DefaultBox from "../components/UI/DefaultBox";
import {policyText} from "../consts/texts";
import {formatText} from "../utils/formatText";
import {Helmet} from "react-helmet";

const PolicyPage = () => {
    const heroContent =
        <HeroContent
            header={"Политика конфиденциальности"}
        />;
    return (
        <Layout heroContent={heroContent}>
            <Helmet><title>Политика конфиденциальности</title></Helmet>

            <DefaultBox sx={{ mb: 4 }}>
                <Typography variant="ch3" gutterBottom>
                    {policyText.title}
                </Typography>
                <Typography variant="desc">
                    {formatText(policyText.text)}
                </Typography>
            </DefaultBox>
        </Layout>

    )
}

export default PolicyPage