import {Typography } from '@mui/material';
import GridItemNavBar from "../UI/GridItemNavBar";
import LinkButton from "../UI/Button/LinkButton";
import TopContainer from "../UI/TopContainer";
import RefButton from "../UI/Button/RefButton";

function Header() {
    return (
            <TopContainer type={"header"}>
                <GridItemNavBar size={{ md: 4, xs: 2 }} align={"left"}>
                    <LinkButton link={"/"} title={"SIP Houses"} variant={"h4"}/>
                </GridItemNavBar>
                <GridItemNavBar size={{ md: 2, xs: 6 }} display={"flex"}>
                    <Typography color={"forText"}>г. Москва</Typography>
                </GridItemNavBar>
                <GridItemNavBar size={{ md: 2, xs: 6 }} display={"flex"}>
                    <RefButton title={"+7 (966) 171-67-61"} href={"tel:+79661716761"}/>
                </GridItemNavBar>
                <GridItemNavBar size={{ md: 2, xs: 2 }}>
                    <Typography color="forText" variant="body1">
                        Пн-Вс: 9:00 – 18:00
                    </Typography>
                </GridItemNavBar>
                <GridItemNavBar size={{ md: 2, xs: 2 }}>
                    <RefButton title={"housesip@yandex.ru"} href={"mailto:housesip@yandex.ru"}/>
                </GridItemNavBar>
            </TopContainer>
    );
}

export default Header;
