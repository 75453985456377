import React from 'react';
import HouseService from '../api/HouseService';
import BaseDetail from '../components/BaseDetail';

const categories = [
    { title: 'Одноэтажные дома', link: '/houses?floors=1' },
    { title: 'Двухэтажные дома', link: '/houses?floors=2' },
    { title: 'Дома с террасой', link: '/houses?terrace=true' },
    { title: 'Дома с гаражом', link: '/houses?garage=true' },
];

function mapHouseData(raw) {
    return {
        id: raw.id,
        title: raw.title,
        price: raw.price,
        description: raw.description || '',
        main_image: raw.main_image,
        other_images: raw.other_images || [],
        floors: raw.floors || 0,
        garage: raw.garage || false,
        terrace: raw.terrace || false,
        topParams: [
            { label: 'Размер', value: `${raw.size} м²` },
            { label: 'Терраса', value: `${raw.terrace_area} м²` },
            { label: 'Площадь', value: `${raw.area} м²` },
            { label: 'Спальни', value: raw.bedrooms },
        ],
    };
}

function HousePage() {
    return (
            <BaseDetail
                isHouse={true}
                service={HouseService}
                mapData={mapHouseData}
                categories={categories}
            />

    );
}

export default HousePage;
