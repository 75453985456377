import * as Yup from "yup";
export const baseFieldsSchema = {
    phone: Yup.string()
        .matches(/^\+7\d{10}$/, "Введите номер в формате +70000000000")
        .required("Укажите номер телефона"),

    clientName: Yup.string()
        .matches(/^[А-Яа-я]+$/, "Только русские буквы без пробелов")
        .max(30, "Максимум 30 символов")
        .required("Укажите ваше имя"),
};