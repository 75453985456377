import React, {useEffect, useState} from "react";
import {useFetching} from "../../hooks/useFetching";
import PortfolioService from "../../api/PortfolioService";
import DefaultBox from "../../components/UI/DefaultBox";
import {Box, Button, Card, Grid2, IconButton, Typography} from "@mui/material";
import PortfolioImage from "../../components/UI/PortfolioImage";
import ModalImage from "../../components/UI/ModalImage";
import {Delete} from "@mui/icons-material";
import LabelBox from "../../components/UI/LabelBox";
import CustomPagination from "../../components/UI/CustomPagination";
import {usePagination} from "../../hooks/usePagination";

const PortfolioPageAdmin = () => {
    const token = localStorage.getItem("token");
    const [entities, setEntities] = useState([]);
    const {page, handlePageChange} = usePagination()

    const [selectedFile, setSelectedFile] = useState(null);

    const handleFileChange = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            setSelectedFile(e.target.files[0]);
        }
    };
    const [totalPages, setTotalPages] = useState(1);
    const limit = 12
    const [modalImage, setModalImage] = useState('');
    const [openModalImage, setOpenModalImage] = useState(false);
    const handlePhotoClick = (url) => {
        setModalImage(url);
        setOpenModalImage(true);
    }
    const handleSubmitForm = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        if (selectedFile) {
            formData.append('file', selectedFile);
        }

        const new_file = await PortfolioService.create(formData, token);

        setEntities((prev) => [...prev, new_file])
    };
    const [fetchEntities, isEntitiesLoading, entitiesErrors] = useFetching(
        async () => {
            const  response = await PortfolioService.getAll({ page, limit });
            setEntities(response.data);
            const totalItems = response.totalItems;
            setTotalPages(Math.ceil(totalItems / limit));
        }
    )
    const handleDeleteClick = async (id) => {
        try {
            await PortfolioService.deleteById(id, token)
            setEntities(prevEntities => prevEntities.filter(entity => entity.id !== id));
        } catch (error) {
            alert(error.message);
        }
    }
    useEffect(() => {
        fetchEntities();
    }, [page])
    return (
        <DefaultBox>
            <DefaultBox sx={{width:'90%'}}>
                    <DefaultBox sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <Typography variant="h5">Портфолио</Typography>
                        <form onSubmit={handleSubmitForm}>
                            <Button
                                variant="outlined"
                                component="label"
                            >
                            <input
                            type="file"
                            accept="image/*"
                            required={true}
                            onChange={handleFileChange}
                            style={{ display: 'block' }}
                        />
                            </Button>

                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                        >
                            Загрузить
                        </Button>
                        </form>
                    </DefaultBox>
            </DefaultBox>
            <Grid2 container spacing={2}>
                {(isEntitiesLoading ? Array.from(new Array(6)) : entities).map((item, index) => (
                    <Grid2 item size={{md: 2, xs: 12, sm: 12, lg: 2}} key={index}>
                        <Card>
                        <Box sx={{ position: 'relative' }}>
                            <PortfolioImage item={item} handleClick={handlePhotoClick} />
                            <LabelBox >
                                <IconButton color="error" onClick={() => handleDeleteClick(item.id)}>
                                    <Delete />
                                </IconButton>
                            </LabelBox>
                        </Box>
                        </Card>
                    </Grid2>
                ))}

                <ModalImage
                    open={openModalImage}
                    onClose={()=> setOpenModalImage(false)}
                    imageUrl={modalImage}
                />
            </Grid2>
            <DefaultBox>
                <CustomPagination page={page} totalPages={totalPages} handleChange={handlePageChange} />

            </DefaultBox>

        </DefaultBox>
    )
}

export default PortfolioPageAdmin;