import HeroContent from "../components/layout/HeroContent";
import Layout from "../components/layout/Layout";
import Error from "../components/Error/Error";
import {Helmet} from "react-helmet";
import React from "react";

const NotFoundPage = () => {
    const heroContent =
        <HeroContent
            header={"Страница не существует"}
        />;
    return (
        <Layout heroContent={heroContent}>
            <Helmet><title>Не найдено</title></Helmet>
            <Error error={"404 Not Found"}/>
        </Layout>

    )
}
export default NotFoundPage;