export const housesLinks = [
    {
        link: "/houses",
        title: "Все дома",
    },
    {
        link: "/houses?floors=1",
        title: "Одноэтажные",
    },
    {
        link: "/houses?floors=2",
        title: "Двухэтажные",
    },
    {
        link: "/houses?terrace=true",
        title: "С террасой",
    },
    {
        link: "/houses?garage=true",
        title: "С гаражом",
    },
]
export const townhousesLinks = [

    {
        link: "/townhouses?apartments=2",
        title: "2 квартиры",
    },
    {
        link: "/townhouses?apartments=4",
        title: "4 квартиры",
    },
    {
        link: "/townhouses?apartments=8",
        title: "8 квартир",
    },
    {
        link: "/townhouses?apartments=16",
        title: "16 квартир",
    },
]
export const companyLinks = [

    {
        link: "/about",
        title: "О компании",
    },
    {
        link: "/portfolio",
        title: "Портфолио",
    },
    {
        link: "/reviews",
        title: "Отзывы",
    },
]
export const clientsLinks = [
    {
        link: "/credit",
        title: "Кредит",
    },
    {
        link: "/payments",
        title: "Способы оплаты",
    },
    {
        link: "/guarantees",
        title: "Гарантии",
    },
    {
        link: "/panels",
        title: "Прайс-лист",
    },
]
export const addLinks = [
    {
        link: "/policy",
        title: "Политика конфиденциальности",
    },
    {
        link: "/calculate",
        title: "Калькулятор",
    },
]