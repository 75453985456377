import FormService from "../../api/FormService";
import ImageButton from "../UI/Button/ImageButton";
import DefaultBox from "../UI/DefaultBox";
import {useFormContext} from "react-hook-form";
import { schemaWithFile } from "../../schemas/schemaWithFile";
import BaseForm from "./BaseForm";
import {Typography} from "@mui/material";
function ImageForm() {


    const handleSuccess = async (data) => {
        const formData = new FormData();
        formData.append('client_name', data.clientName);
        formData.append('phone', data.phone);
        if (data.file[0]){
            formData.append('file', data.file[0]);
        }
        await FormService.createImageRequest(formData);
    };
    return (
        <DefaultBox>
            <BaseForm
                image="/images/project.jpg"
                title="Отправьте свой проект"
                description="Можем создать уникальный проект с нуля, адаптировать любой существующий проект под ваши требования или воплотить в жизнь проект по вашим фото из других источников."
                schema={schemaWithFile}
                onSubmitSuccess={handleSuccess}
            >
                <AdditionalFieldsFile/>
            </BaseForm>
        </DefaultBox>

    );
}
function AdditionalFieldsFile() {
    const { register, watch } = useFormContext();
    const file = watch("file");
    const fileName = file && file.length ? file[0].name : "";
    return (
        <DefaultBox sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            alignItems: { xs: "flex-start", md: "center" },
            gap: 2,
            py:0, mt: 1
        }}>
            <ImageButton {...register("file")}/>
            {fileName && (
                <Typography variant="body4" sx={{ ml: 2 }}>
                    {fileName}
                </Typography>
            )}
        </DefaultBox>
    );
}
export default ImageForm;
