import {Skeleton, Typography} from "@mui/material";
import React from "react";

const Price = ({price, isLoading})=> {
    if (isLoading) {
        return (
            <Skeleton width="100%">
                <Typography variant="h4">.</Typography>
            </Skeleton>
        )
    }
    return (
        <Typography variant="h4" sx={{ mb: 2,  alignSelf: "center" }}>
            {price} Р
        </Typography>
    )
}

export default Price