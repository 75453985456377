import BaseRequestAdmin from "./BaseRequestAdmin";

const CalcReqPage = () => {
    const fields = [{ name: 'extra_data', label: 'Дополнительные параметры', type: 'text', required: true },]
    return (
        <BaseRequestAdmin
            fields={fields}
            request_type={'CALC_FORM'}
            header={"На расчет"}
        />
    )
}
export default CalcReqPage;