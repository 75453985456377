import BaseRequestAdmin from "./BaseRequestAdmin";

const HouseReqPage = () => {
    const fields = [{ name: 'house_id', label: 'ID дома', type: 'text', required: true }]
    return (
        <BaseRequestAdmin
            fields={fields}
            request_type={'HOUSE_FORM'}
            header={"По домам"}

        />
    )
}
export default HouseReqPage;