import BaseFilter from "./BaseFilter";
import {Typography} from "@mui/material";
import DefaultBox from "../UI/DefaultBox";
import React from "react";


const TownhouseFilters = ({ searchParams, setSearchParams, setPage }) => {
    const apartments = searchParams.get("apartments");
    const apartmentsNum = apartments ? Number(apartments) : null;
    const updateParams = (obj) => {
        const paramsObj = {};
        if (obj.apartments) paramsObj.apartments = obj.apartments;
        setSearchParams(paramsObj);
    }
    const handleApartmentsFilter = (value) => {
        const newApartments =(apartments === value) ? null : value;
        updateParams({apartments: newApartments})
        setPage(1);
    };
    const isActive= (value) => {
        return apartmentsNum === value;
    };
    return (
        <DefaultBox>
            <DefaultBox sx={{
                display: "flex",
                alignItems: { xs: "flex-start", md: "center" },
                gap: 2,
                py: 0,
                flexDirection: { xs: "column", sm: "column", md: "row" }
            }}>
                <Typography variant="h4" sx={{ flexShrink: 0 }}>Количество квартир:</Typography>
                <BaseFilter
                    func={(num) => Math.pow(2, num + 1)}
                    handleFilter={handleApartmentsFilter}
                    isActive={isActive}
                    list = {[...Array(4)]}
                />
            </DefaultBox>
        </DefaultBox>
    )
}

export default TownhouseFilters;