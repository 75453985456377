import {Link} from "react-router-dom";
import {Button, Skeleton} from "@mui/material";

const CardButton = ({link, isLoading}) => {
    if (isLoading) {
        return (
            <Skeleton width="100%">
                <Button
                    fullWidth
                    sx={{
                        px: 0,
                        textTransform: 'none',
                        typography: "h6"
                    }}>.
                </Button>
            </Skeleton>
        )
    }
    return (
        <Button
            component={Link}
            fullWidth={true}
            to={link}
            sx={(theme)=> ({
                px: 0,
                textTransform: 'none',
                typography: "h6",
                color: "white",
                backgroundColor: theme.palette.primary.btns,
            })}
        >
            Подробнее
        </Button>
    )

}

export default CardButton