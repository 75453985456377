
import TownhouseService from "../../api/TownhouseService";
import BasePage from "./BasePage";

const TownhousePageAdmin = () => {
    const fields = [

        { name: 'title', label: 'Название', type: 'text'},
        { name: 'price', label: 'Цена', type: 'number'},
        { name: 'apartments', label: 'Кол-во квартир', type: 'number'},
        { name: 'description', label: 'Описание', type: 'text'},
        { name: 'total_area', label: 'Площадь', type: 'number'},
        { name: 'count_rooms', label: 'Кол-во комнат в кВ', type: 'number'},
        { name: 'apartment_area', label: 'Площадь комнаты', type: 'number'},

        { name: 'main_image', label: 'Основное изображение', type: 'file'},
        { name: 'other_images', label: 'Другие изображения', type: 'file', multiple: true },
    ];

    return (
        <BasePage
            header={"Таунхаусы"}
            fields={fields}
            service={TownhouseService}
        />
    )
}

export default TownhousePageAdmin