import React from 'react';
import BaseCard from '../BaseCard';

function TownhouseCard({ item }) {
    const parameters = [
        { label: 'Общая площадь', value: item ?  `${item.total_area}` : null },
        { label: 'Площадь квартиры', value: item ? `${item.apartment_area}`: null },
        { label: 'Кол-во квартир', value: item ? item.apartments : null},
        { label: 'Кол-во спален в квартире', value:  item ?item.count_rooms : null},
    ];
    const title = item ? item.title : null;
    const price = item ? item.price : null;
    const imageURL = item ? item.main_image : null;
    const link = item ? `/townhouses/${item.id}` : null;
    const isLoading = !item;
    return (
        <BaseCard
            isLoading={isLoading}
            imageUrl={imageURL}
            title={title}
            price={price}
            parameters={parameters}
            link={link}
        />
    );
}

export default TownhouseCard;