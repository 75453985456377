import React, { useState, useEffect } from 'react';
import {useFetching} from "../hooks/useFetching";
import ReviewsService from "../api/ReviewsService";
import Layout from "../components/layout/Layout";
import ReviewCard from "../components/ReviewCard/ReviewCard";
import HeroContent from "../components/layout/HeroContent";
import DefaultBox from "../components/UI/DefaultBox";
import Error from "../components/Error/Error";
import CustomPagination from "../components/UI/CustomPagination";
import {usePagination} from "../hooks/usePagination";
import {Helmet} from "react-helmet";

function ReviewsPage() {
    const {page, handlePageChange} = usePagination()
    const [totalPages, setTotalPages] = useState(5);
    const limit = 5

    const [reviews, setReviews] = useState([]);
    const [fetchReviews, isReviewsLoading, reviewsErrors] = useFetching(
        async () => {
            const query = {}
            query.page = page
            query.limit = limit
            const response = await ReviewsService.getAll(query)
            setReviews(response.data)
            const totalItems = response.totalItems
            setTotalPages(Math.ceil(totalItems / limit));
        }
    )

    useEffect(() => {
        fetchReviews();
    }, []);

    const heroContent =
        <HeroContent
            header={"Отзывы клиентов"}
        />;
    return (
        <Layout heroContent={heroContent}>
            <Helmet><title>Отзывы</title></Helmet>

            {reviewsErrors && <Error error={reviewsErrors}/>}
                {!reviewsErrors &&
                    <DefaultBox >
                        <DefaultBox>
                            {(isReviewsLoading ? Array.from(new Array(2)) : reviews).map((item, index) => (
                                <ReviewCard review={item} key={index} />
                            ))}
                        </DefaultBox>
                        <DefaultBox sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
                            <CustomPagination
                                totalPages={totalPages}
                                page={isReviewsLoading ? page : page}
                                handleChange={handlePageChange}
                            />
                        </DefaultBox>
                    </DefaultBox>
                }
        </Layout>

    );
}


export default ReviewsPage;
