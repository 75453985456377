import BasePage from "./BasePage";
import ReviewsService from "../../api/ReviewsService";

const ReviewPageAdmin = () => {
    const fields = [

        { name: 'client_name', label: 'Имя', type: 'text'},
        { name: 'rating', label: 'Рейтинг', type: 'number'},
        { name: 'intro', label: 'Интро', type: 'text'},
        { name: 'main_text', label: 'Основной текст', type: 'text'},
        { name: 'image', label: 'Изображение', type: 'file'},
    ];

    return (
        <BasePage
            header={"Отзывы"}
            fields={fields}
            service={ReviewsService}
        />
    )
}

export default ReviewPageAdmin;