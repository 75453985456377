import { useState } from 'react';

export function usePagination(initialPage = 1) {
    const [page, setPage] = useState(initialPage);

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    return {
        page,
        handlePageChange,
        setPage,
    };
}