import {TextField} from "@mui/material";
import React from "react";

const InputField = ({sx={}, label, error, helperText,placeholder="", ...props}) => {
    const defaultStyles = {
        width: "100%",
        mb: 2, mt: 2, flex: 1,
        input: { color: "white" },
        label: { color: "lightgray" },
        "& label.Mui-focused": { color: "white" },
        "& .MuiOutlinedInput-root": {
            "&:hover fieldset": { borderColor: "white" },
            "&.Mui-focused fieldset": { borderColor: "white" },
        },
        backgroundColor: "black",
        color: "white",
        borderRadius: "5px",
        borderColor: (theme) => theme.palette.primary.second,
        "& .MuiFormHelperText-root": {
            color: "red",
            backgroundColor: "inherit",
            padding: "2px 4px",
            borderRadius: "5px",
        },
    }
    const styles = {...defaultStyles, ...sx}
    return (
        <TextField
            label={label}
            error={error}
            helperText={helperText}
            {...props}
            required
            placeholder={placeholder}
            sx={styles}
        />
    )
}

export default InputField;