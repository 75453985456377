import CalcForm from "../components/Form/CalcForm";
import Layout from "../components/layout/Layout";
import HeroContent from "../components/layout/HeroContent";
import {Card} from "@mui/material";
import {Helmet} from "react-helmet";
import React from "react";
const CalculatePage = () => {
    const heroContent =
        <HeroContent
            header={"Калькулятор"}
        />;
    return (
        <Layout heroContent={heroContent}>
            <Helmet><title>Калькулятор</title></Helmet>
            <Card sx={{my: 2, px: 2, backgroundColor: (theme) => theme.palette.primary.bgForm}}>
                <CalcForm/>
            </Card>

        </Layout>

    )
}
export default CalculatePage