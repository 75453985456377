import {Link} from "react-router-dom";
import {Button} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const MenuButton = ({
                        title,
                        onClick
                    }) => {

    return (
        <Button
            onClick={onClick}
            sx={{
                position: 'relative',
                minWidth: '130px',
                textTransform: 'none',
                justifyContent: 'center',
                paddingRight: '30px',
                typography: "body2",
            }}

        >
            {title}
            <KeyboardArrowDownIcon sx={{
                position: 'absolute',
                right: '8px',
                top: '50%',
                transform: 'translateY(-50%)',
            }} />
        </Button>

    )
}


export default MenuButton